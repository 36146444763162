<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
        <div class="d-flex align-items-center">
          <h4>Vector: {{ json_data.name }}</h4>
          <div class="star-container ml-2 mb-2">
              <span class="star"> ★</span>
              <span class="premium-text">Premium Page</span>
            </div>
          </div>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/vector?key=1
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
export default {
  name: "VectorDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      base_url: "https://tair-data.s3-us-west-2.amazonaws.com/prod/vector/",
      json_data: null,
      side_cols: ["Summary"],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {},
    };
  },

  async mounted() {
    let jsonId = this.$route.query.key;
    if (jsonId) {
      try {
        let response = await PostsService.getVectorDetail({ key: jsonId });
        this.json_data = response.data;

        this.loading = true;
        this.processEntries();
        this.loading = false;
        document.title = `Vector Detail`;
      } catch (error) {
        console.error("Error fetching vector details: ", error);
        this.loading = false;
        this.error = true;
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  },
  methods: {
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push(this.getStocks());
      bands.push({
        key: "Name",
        text: this.json_data.name,
      });
      bands.push({
        key: "Date Last Modified",
        text: this.dateToYMD(this.json_data.dateLastModified),
      });
      bands.push({
        key: "TAIR Accession",
        text: `Vector:${this.json_data.tairObjectId}`,
      });
      // bands.push({
      //   key: "Associated Locus",
      //   text: this.json_data.locus.locusName,
      //   link: `/locus?key=${this.json_data.locus.locusId}`,
      // });
      bands.push({
        key: "Type",
        text: this.json_data.vectorType,
      });
      bands.push({
        key: "Description",
        text: this.json_data.description,
      });

      bands.push(this.getHostStrain());

      bands.push({
        key: "Promoter:Reporter Names",
        text: this.json_data.promoterName
          ? this.json_data.promoterName
          : "Unknown",
      });
      bands.push({
        key: "Promoter:Reporter Information",
        text: this.json_data.promoterInfo
          ? this.json_data.promoterInfo
          : "Unknown",
      });
      bands.push({
        key: "Selection",
        text: this.json_data.antibioticResistance
          ? this.json_data.antibioticResistance
          : "Unknown",
      });
      bands.push(this.getLibraryLinks());
      bands.push(this.getImages());
      bands.push(this.getComments());
      bands.push(this.getAttributions());
      // bands.push(this.getAssociatedUrls());
      return bands;
    },

    //Getters
    getStocks() {
      let entry = {
        key: "Stock Information",
        type: "multiline",
        items: [],
        fields: [],
        buttons: [],
      };
      entry.fields.push({
        key: "stockNo",
        label: "Stock #",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "availability",
        label: "Availability",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "donors",
        label: "Donors",
        cellType: "link_list",
      });
      entry.fields.push({
        key: "donorStockNo",
        label: "Donor Stock #",
        cellType: "name_link",
      });
      //   entry.fields.push({
      //     key: "description",
      //     label: "Description",
      //     cellType: "name_link",
      //     width: "100%",
      //   });

      let stocks = this.json_data.stockDetails;
      if (stocks == null || stocks.length == 0) {
        entry.type = "";
        entry.text =
          "Search at the stock centers or contact an author of publication(s) describing this line.";
        return entry;
      }
      let stockMap = {};
      stocks.forEach((s) => {
        if (!stockMap[s.stockId]) {
          stockMap[s.stockId] = [];
        }
        stockMap[s.stockId].push(s);
      });

      Object.keys(stockMap).forEach((stockId) => {
        let stocks = stockMap[stockId];
        let donors = [];
        stocks.forEach((s) => {
          if (s.communityType == "person") {
            donors.push({
              name: `${s.firstName} ${s.lastName}`,
              link: `/person?key=${s.communityId}`,
            });
          } else if (s.communityType == "organization") {
            donors.push({
              name: s.organizationName,
              link: `/organization?key=${s.communityId}`,
            });
          }
        });

        let s = stocks[0];
        let item = {};
        item.stockNo = {
          name: s.name,
          link: `https://abrc.osu.edu/stocks/number/${s.name}`,
        };
        item.availability = {
          name: s.stockAvailabilityType,
        };
        item.donors = donors;
        item.donorStockNo = {
          name: s.donorStockNumber,
        };
        item.description = {
          name: s.description,
        };
        entry.items.push(item);
      });

      return entry;
    },
    getLibraryLinks() {
      let entry = {
        key: "Library Links",
        type: "link_list",
        items: [],
      };
      let libraryLinks = this.json_data.libraryVectors;
      if (libraryLinks == null || libraryLinks.length == 0) {
        entry.type = "";
        entry.text = "No Library Links available";
        return entry;
      }
      libraryLinks.forEach((l) => {
        entry.items.push({
          text: l.name,
          link: "/library?key=" + l.libraryId,
        });
      });
      return entry;
    },
    getHostStrain() {
      let entry = {
        key: "Host Strain",
        type: "table",
        items: [],
        fields: [],
      };
      let hostStrain = this.json_data.hostStrain;
      if (!hostStrain) {
        entry.type = "";
        entry.text = "No Host Strains available";
        return entry;
      }
      entry.fields = [
        {
          key: "genus",
          label: "Genus",
          cellType: "name_link",
        },
        {
          key: "species",
          label: "Species",
          cellType: "name_link",
        },
        {
          key: "strainName",
          label: "Strain Name",
          cellType: "name_link",
        },
      ];
      let s = hostStrain;
      let item = {
        genus: {
          name: s.genus,
        },
        species: {
          name: s.species,
        },
        strainName: {
          name: s.originalName,
          link: `hoststrain?key=${s.hostStrainId}`,
        },
      };
      entry.items.push(item);
      return entry;
    },
    getImages() {
      let entry = {
        key: "Images",
        type: "img_list",
        items: [],
      };
      let images = this.json_data.imageDetails;
      if (images == null || images.length == 0) {
        entry.type = "";
        entry.text = "No images available";
        return entry;
      }
      images.forEach((i) => {
        entry.items.push({
          full_url:
            process.env.VUE_APP_OLD_TAIR_URL +
            "/jsp/common/image.jsp?id=" +
            i.imageId +
            "&format=jpg",
          img_url:
            process.env.VUE_APP_OLD_TAIR_URL +
            "/servlets/images/thumb_" +
            i.imageId +
            ".jpg",
        });
      });
      return entry;
    },
    getAttributions() {
      let entry = {
        key: "Attribution",
        type: "table",
        items: [],
        fields: [],
      };
      let attributions = this.json_data.attributions;
      if (attributions == null || attributions.length == 0) {
        entry.type = "";
        entry.text = "No Attributions available";
        return entry;
      }
      entry.fields = [
        {
          key: "type",
          label: "Type",
          cellType: "name_link",
        },
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "date",
          label: "Date",
          cellType: "name_link",
        },
      ];
      attributions.forEach((a) => {
        let name = a.organizationName;
        if (a.firstName && a.lastName) {
          name = `${a.firstName} ${a.lastName}`;
        }
        let a_link = "";
        if (a.communityType == "organization") {
          a_link = `/organization?key=${a.communityId}`;
        } else {
          a_link = `/person?key=${a.communityId}`;
        }
        let item = {
          type: { name: a.attributionType },
          name: {
            name: name,
            link: a_link,
          },
          date: { name: this.dateToYMD(a.attributionDate) },
        };
        entry.items.push(item);
      });
      return entry;
    },
    getComments() {
      let entry = {
        key: "Community Comments",
        type: "comment_list",
        showComments: true,
        showRecent: true,
        items: [],
        helpTxt: this.helpTexts.comments,
      };
      let items = [];
      if (this.json_data.comments) {
        this.json_data.comments.forEach((c) => {
          let item = {};
          item.profile = {
            name: c.firstName,
            url: "/person?key=" + c.communityId,
          };
          item.comment = {
            text: c.notepadComment,
            url:
              process.env.VUE_APP_OLD_TAIR_URL +
              "/servlets/TairObject?type=notepad&id=" +
              1,
          };
          item.date_posted = this.dateToYMD(c.dateEntered);
          items.push(item);
        });
      }
      entry.items = items;
      if (entry.items.length == 0) {
        entry.items.push({ comment: { text: "No comments found" } });
      }
      return entry;
    },
    getPublications() {
      let entry = {
        key: "Publications",
        type: "table",
        items: [],
        fields: [],
      };
      let publications = this.json_data.publications;
      if (publications == null || publications.length == 0) {
        entry.type = "";
        entry.text = "No publications available";
        return entry;
      }
      entry.fields = [
        {
          key: "title",
          label: "Title",
          cellType: "name_link",
        },
        {
          key: "source",
          label: "Source",
          cellType: "name_link",
        },
        {
          key: "year",
          label: "Year",
          cellType: "name_link",
        },
      ];
      publications.forEach((p) => {
        let item = {
          title: { name: p.title, link: `/publication?key=${p.referenceId}` },
          source: { name: p.sourceName },
          year: { name: p.publicationYear },
        };
        entry.items.push(item);
      });
      return entry;
    },
    getAssociatedUrls() {
      let entry = {
        key: "Associated URLs",
        type: "link_list",
        items: [],
      };

      return entry;
    },
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}


</style>
