import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import { downloadItems } from "./modules/downloadItems";
import { newsURLs } from "./modules/newsURLs";
import { authProfile } from "./modules/authProfile";
import { subscriptionStatus } from "./modules/subscriptionStatus";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "tair", // The key to store the Vuex state in the storage
  storage: window.localStorage,
});

const store = new Vuex.Store({
  modules: {
    authProfile,
    subscriptionStatus,
    downloadItems,
    newsURLs,
  },
  getters: {
    userStatus: (state, getters) => ({
      isLoggedIn: getters['authProfile/isLoggedIn'],
      orcidId: getters['authProfile/getOrcidId'],
      subscriptionStatus: getters['subscriptionStatus/status']
    })
  },
  plugins: [vuexPersist.plugin],
});

// Load auth profile when the store is created
store.dispatch('authProfile/loadAuthProfile');

export default store;