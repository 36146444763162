<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
        <div class="d-flex align-items-center">
          <h4>Transposon Family: {{ json_data.transposonFamilyName }}</h4>
          <div class="star-container ml-2 mb-2">
              <span class="star"> ★</span>
              <span class="premium-text">Premium Page</span>
            </div>
          </div>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/transposonfamily?key=128
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";

export default {
  name: "TransposonFamilyDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "transposonfamily/",
      json_data: null,
      side_cols: ["Summary"],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {},
    };
  },

  async mounted() {
    document.title = "Transposon Family Detail";
    let jsonId = this.$route.query.key;
    if (jsonId) {
      try {
        let response = await PostsService.getTransposonFamilyDetail({
          key: jsonId,
        });
        this.json_data = response.data;

        this.loading = true;
        this.processEntries();
        this.loading = false;
        document.title = `Transposon Family Detail`;
      } catch (error) {
        console.error("Error fetching Transposon Family details: ", error);
        this.loading = false;
        this.error = true;
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  },
  methods: {
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.transposonFamilyName,
      });
      bands.push({
        key: "Date Last Modified",
        text: this.dateToYMD(this.json_data.dateLastUpdated),
      });
      bands.push({
        key: "Super Family Name",
        text: this.json_data.superFamilyName,
      });
      bands.push(this.getAssociatedTransposons());
      return bands;
    },

    //Getters
    getAssociatedTransposons() {
      let entry = {
        key: "Associated Transposons",
        type: "table",
        items: [],
        fields: [],
      };
      entry.fields = [
        { key: "name", text: "Name", cellType: "name_link" },
        { key: "chromosome", text: "Chromosome", cellType: "name_link" },
        { key: "Coordinates", text: "Coordinates", cellType: "name_link" },
      ];
      this.json_data.associatedTransposons.forEach((t) => {
        entry.items.push({
          name: { name: t.name, link: `/transposon?key=${t.transposonId}` },
          chromosome: { name: t.chromosome },
          Coordinates: { name: `${t.startPosition} ... ${t.endPosition}` },
        });
      });
      entry.count = entry.items.length;
      return entry;
    },
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}


</style>
