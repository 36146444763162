<template>
    <div>
      <div v-if="!error" class="detail-wrapper">
        <div class="side-wrapper">
          <ul class="list-group list-group-flush">
            <a
              v-for="(col, i) in side_cols"
              :key="i"
              href="#"
              class="list-group-item list-group-item-action flex-column align-items-start side-item"
              v-bind:class="{ selected: i == colIdxSelected }"
              @click="sideColClicked(i)"
            >
              {{ col }}
            </a>
          </ul>
        </div>
        <div class="list-wrapper" v-on:scroll.passive="handleScroll">
          <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center"
            style="height: 100%"
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div
            v-if="json_data"
            class="text-left m-2 font-weight-bold fixed-title"
          >
          <div class="d-flex align-items-center">
            <h4>Sequence: {{ json_data.name }}</h4>
            <div class="star-container ml-2 mb-2">
              <span class="star"> ★</span>
              <span class="premium-text">Premium Page</span>
            </div>
          </div>
          </div>
          <BaseEntry
            v-for="(e, i) in entries"
            :selectedColIdx="colIdxSelected"
            :currIdx="i"
            :key="i"
            :content="e"
            :ref="`entry` + i"
          ></BaseEntry>
        </div>
      </div>
      <div v-else>
        <PageNotFound />
      </div>
    </div>
  </template>
  
  <script>
  import BaseEntry from "@/components/detail/BaseEntry";
  import PostsService from "@/services/PostsService";
  import PageNotFound from "@/components/common/PageNotFound";
  
  export default {
    name: "SequenceDetail",
    components: {
      BaseEntry,
      PageNotFound,
    },
    data() {
      return {
        base_url: process.env.VUE_APP_S3_PATH + "sequence/",
        json_data: null,
        side_cols: [
          "Sequence",
          "Summary",
          "Publications",
        ],
        loading: false,
        error: false,
        colIdxSelected: 0,
        entries: [],
        entryPosys: [],
        helpTexts: {},
      };
    },
  
    async mounted() {
      this.loading = true;
      document.title = "Sequence Detail";
      let jsonId = this.$route.query.key;
      console.log(jsonId + "");

      if (jsonId) {
        try {
          this.loading = true;
          let response = await PostsService.getSequenceDetail({ key: jsonId });
          this.json_data = response.data;
          console.log("test");
  
          this.processEntries();
          this.loading = false;
          document.title = `Sequence Detail`;
        } catch (error) {
          console.error("Error fetching sequence details: ", error);
          this.loading = false;
          this.error = true;
        }
      } else {
        this.loading = false;
        this.error = true;
      }
    },
    methods: {
      sideColClicked(colIdx) {
        this.scrollToElement(colIdx);
        this.colIdxSelected = colIdx;
      },
      processEntries() {
        this.side_cols.forEach((title) => {
          let entry = {
            title: title,
            name: title.replace(/\s/g, ""),
          };
          switch (title) {
            case "Summary":
              entry.type = "bands";
              entry.bands = this.fillSummary();
              break;
            case "Sequence":
                entry.type = "bands";
                entry.bands = this.fillSequences();
                break;
            case "Publications":
              entry.type = "bands";
              entry.bands = this.fillPublications();
              break;
            default:
              entry.type = "bands";
              entry.bands = [];
              break;
          }
          this.entries.push(entry);
        });
      },
      fillSummary() {
        let bands = [];
        bands.push({
          key: "Name",
          text: this.json_data.name,
        });
        bands.push({
          key: "Date Last Modified",
          text: this.dateToYMD(this.json_data.dateLastModified),
        });
        bands.push({
          key: "TAIR Accession",
          text: `${this.json_data.tairObjectId}`,
        });
        bands.push({
            key: "GenBank Accession",
            type: "link",
            text: `${this.json_data.icAccession}`,
            link: "https://www.ncbi.nlm.nih.gov/nucleotide/" + this.json_data.icAccession,
        });
        bands.push({
          key: "Sequence Length (bp)",
          text: this.json_data.length,
        });
        bands.push(this.getComments());
        bands.push(this.getAttributtions());
        return bands;
      },
      fillPublications() {
        let bands = [];
        bands.push(this.getPublications());
        return bands;
      },
      fillSequences() {
        let bands = [];
        bands.push(this.getSequence());
        return bands;
      },
  
      //getters
      getSequence() {
        let entry = {
            key: "Sequence",
            type: "sequence",
            sequences: [],
        };
        if (this.json_data.sequence) {
            const lines = [];
            const sequenceLength = this.json_data.sequence.length;
            let lineNumber = 1;

            for (let i = 0; i < sequenceLength; i += 50) {
            const lineChunks = [];
            for (let j = i; j < i + 50 && j < sequenceLength; j += 10) {
                lineChunks.push(this.json_data.sequence.slice(j, j + 10));
            }

            lines.push(
                `${lineNumber.toString().padStart(3, "0")} ${lineChunks.join(" ")}`
            );
            lineNumber += 50;
            }
            entry.chunks = lines;
            entry.openLink = () => {
              // this.openPostLink(
              //     process.env.VUE_APP_OLD_TAIR_URL + "/Blast/index.jsp",
              //     {
              //     sequence_type: "protein",
              //     sequence: this.json_data.sequence,
              //     }
              // );
              this.$router.push({
                name: 'Blast', // Name of your Blast route
                query: {
                  sequence_type: "sequence",
                  sequence: this.json_data.sequence,
                }
              });
            };
        } else {
            entry.chunks = ["No sequence available"];
        }
        return entry;
      },
      openPostLink(url, data) {
          // Create a form element
          let form = document.createElement("form");
          form.action = url;
          form.method = "POST";
          form.target = "_blank"; // Open in new tab

          // Add the data as hidden input fields
          for (let key in data) {
              let input = document.createElement("input");
              input.type = "hidden";
              input.name = key;
              input.value = data[key];
              form.appendChild(input);
          }

          // Append the form to the body and submit it
          document.body.appendChild(form);
          form.submit();

          // Remove the form after submission
          document.body.removeChild(form);
      },
      getAttributtions() {
        let entry = {
          key: "Attribution",
          type: "table",
          items: [],
          fields: [],
        };
        let attributions = this.json_data.attributions;
        if (attributions == null || attributions.length == 0) {
          entry.type = "";
          entry.text = "No Attribution available";
          return entry;
        }
        entry.fields = [
          {
            key: "type",
            label: "Type",
            cellType: "name_link",
          },
          {
            key: "name",
            label: "Name",
            cellType: "name_link",
          },
          {
            key: "date",
            label: "Date",
            cellType: "name_link",
          },
        ];
        attributions.forEach((a) => {
          let name = a.organizationName; // Default to the organization name
          if (a.firstName && a.lastName) {
            name = `${a.firstName} ${a.lastName}`; // Use person's name if available
          }

  
          // Determine the correct link based on the communityType
          let linkPath = "/organization?key=" + a.communityId;
          if (a.communityType === "person") {
            linkPath = "/person?key=" + a.communityId; // Adjust path for persons
          }
  
          let item = {
            type: { name: a.attributionType },
            name: {
              name: name,
              link: linkPath,
            },
            date: { name: this.dateToYMD(a.attributionDate) }, // Convert the date to YMD format
          };
  
          entry.items.push(item);
        });
  
        return entry;
      },
      getComments() {
        let entry = {
          key: "Community Comments",
          type: "comment_list",
          showComments: true,
          showRecent: true,
          items: [],
          helpTxt: this.helpTexts.comments,
        };
        let items = [];
        if (this.json_data.comments) {
          this.json_data.comments.forEach((c) => {
            let item = {};
            item.profile = {
              name: c.firstName,
              url: "/person?key=" + c.communityId,
            };
            item.comment = {
              text: c.notepadComment,
              url:
                process.env.VUE_APP_OLD_TAIR_URL +
                "/servlets/TairObject?type=notepad&id=" +
                1,
            };
            item.date_posted = this.dateToYMD(c.dateEntered);
            items.push(item);
          });
        }
        entry.items = items;
        if (entry.items.length == 0) {
          entry.items.push({ comment: { text: "No comments found" } });
        }
        return entry;
      }, 
      getPublications() {
        let entry = {
          key: "",
          type: "table",
          items: [],
          fields: [],
        };
        let publications = this.json_data.publications;
        if (publications == null || publications.length == 0) {
          entry.type = "";
          entry.text = "No publications available";
          return entry;
        }
        entry.fields = [
          {
            key: "title",
            label: "Title",
            cellType: "name_link",
          },
          {
            key: "source",
            label: "Source",
            cellType: "name_link",
          },
          {
            key: "year",
            label: "Year",
            cellType: "name_link",
          },
        ];
        publications.forEach((p) => {
          let item = {
            title: { name: p.title, link: `/publication?key=${p.referenceId}` },
            source: { name: p.sourceName },
            year: { name: p.publicationYear },
          };
          entry.items.push(item);
        });
        entry.count = entry.items.length;
        return entry;
      },
      dateToYMD(dateTs) {
        let date = new Date(dateTs);
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();
        return `${y}-${m.toString().padStart(2, "0")}-${d
          .toString()
          .padStart(2, "0")}`;
      },
      //
      scrollToElement(colIdx) {
        const entryRef = this.$refs["entry" + colIdx];
        const el = entryRef[0].$el;
        if (el) {
          const top = el.offsetTop - 210;
          el.parentElement.scrollTo(0, top);
        }
      },
      handleScroll(e) {
        const scrollTop = e.target.scrollTop + 215;
        let currTopEntry = 0;
        this.entryPosys.forEach((e, i) => {
          if (scrollTop > e) {
            currTopEntry = i;
          }
        });
        this.colIdxSelected = currTopEntry;
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .fixed-title {
    position: sticky;
    top: 0;
    background-color: #f1efec;
    z-index: 10;
    padding-left: 10px;
    padding-top: 10px;
  }
  
  .my-custom-scrollbar {
    position: relative;
    height: 200px;
    overflow: auto;
  }
  .table-wrapper-scroll-y {
    display: block;
  }


  </style>
  