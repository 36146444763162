<template>
  <div class="page-container">
    <div v-if="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="loadingError">
      <div class="alert alert-danger" role="alert">
        Error fetching data. Please try again later.
      </div>
    </div>
    <div class="list-wrapper">
      <!-- Account Edit Form -->
      <div v-if="json_data" class="form-container">
        <form @submit.prevent="editAccount" class="edit-form">
          <h3>Edit Login Details Form</h3>
          <div class="input-group">
            <label for="userName">Username</label>
            <input
              type="text"
              id="userName"
              v-model="json_data.userName"
              class="form-control"
              required
              readonly
            />
          </div>

          <!-- Email -->
          <div class="input-group">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              v-model="json_data.email"
              class="form-control"
              required
            />
          </div>

          <div class="input-group">
            <label for="password">New Password</label>
            <input
              type="password"
              id="password"
              v-model="json_data.password"
              placeholder="Password must be between 6 and 10 characters."
              class="form-control"
            />
            <span class="error-message" v-if="passwordError">{{
              passwordError
            }}</span>
          </div>

          <div class="input-group">
            <label for="password">New Password Confirm</label>
            <input
              type="password"
              id="password_confirm"
              v-model="json_data.password_confirm"
              class="form-control"
              placeholder="Must be same as above."
            />
            <span class="error-message" v-if="confirmPasswordError">{{
              confirmPasswordError
            }}</span>
          </div>

          <div v-if="accountFormErrors.length" class="alert alert-danger mt-5">
            <p v-for="error in accountFormErrors" :key="error">{{ error }}</p>
          </div>
          <!-- Success Message -->
          <div v-if="successMessage2" class="alert alert-success mt-3">
            {{ successMessage2 }}
          </div>
          <!-- Username, Password, and Password Confirm fields here -->
          <button type="submit" class="btn btn-primary" :disabled="isLoading">
            <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-if="!isLoading">Edit Login Details</span>
          </button>
        </form>
      </div>

      <br />

      <!-- Existing Form for updating person details, without the fields moved to the new form -->
      <div v-if="json_data" class="form-container">
        <form
          v-if="json_data"
          @submit.prevent="updatePersonDetails"
          class="edit-form"
        >
          <h3>Update User Data Form</h3>
          <!-- First Name -->
          <div class="input-group">
            <label for="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              v-model="json_data.firstName"
              class="form-control"
              required
            />
          </div>

          <!-- Last Name -->
          <div class="input-group">
            <label for="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              v-model="json_data.lastName"
              class="form-control"
              required
            />
          </div>

          <!-- Institution Name (Extracted from affiliations[0].organizationName as an example) -->
          <div class="input-group">
            <label for="institutionName">Institution Name</label>
            <input
              type="text"
              id="institutionName"
              v-model="json_data.institutionName"
              class="form-control"
              required
            />
          </div>

          <!-- Job Title -->
          <div class="input-group">
            <label for="primaryJobTitle">Job Title</label>
            <input
              type="text"
              id="primaryJobTitle"
              v-model="json_data.primaryJobTitle"
              class="form-control"
            />
          </div>

          <!-- Phone (Extracted from phones array, work phone as an example) -->
          <div
            v-for="phone in json_data.phones"
            :key="phone.phoneType"
            class="input-group"
          >
            <label :for="`phone-${phone.phoneType}`"
              >{{
                phone.phoneType.charAt(0).toUpperCase() +
                phone.phoneType.slice(1)
              }}
              Phone</label
            >
            <input
              type="tel"
              :id="`phone-${phone.phoneType}`"
              v-model="phone.phoneNumber"
              class="form-control"
            />
          </div>

          <!-- Street Address -->
          <div class="input-group">
            <label for="streetAddress">Street Address</label>
            <textarea
              id="streetAddress"
              v-model="formattedAddress"
              class="form-control"
            ></textarea>
          </div>

          <!-- Country -->
          <div class="input-group">
            <label for="country">Country</label>
            <input
              type="text"
              id="country"
              v-model="json_data.country"
              class="form-control"
            />
          </div>

          <!-- State/Province -->
          <div class="input-group">
            <label for="stateProvince">State/Province</label>
            <input
              type="text"
              id="stateProvince"
              v-model="json_data.region"
              class="form-control"
            />
          </div>

          <!-- City -->
          <div class="input-group">
            <label for="city">City</label>
            <input
              type="text"
              id="city"
              v-model="json_data.city"
              class="form-control"
            />
          </div>

          <!-- Zipcode -->
          <div class="input-group">
            <label for="zipcode">Zipcode</label>
            <input
              type="text"
              id="zipcode"
              v-model="json_data.postalCode"
              class="form-control"
            />
          </div>

          <div class="input-group checkbox-inline">
            <input
              type="checkbox"
              id="okMail"
              class="ml-2 mr-2"
              v-model="sendEmail"
            />
            <label class="checkbox-label" for="okMail">
              OK to send occasional email announcements and quarterly TAIR
              newsletter</label
            >
          </div>

          <!-- Success Message -->
          <div v-if="successMessage" class="alert alert-success mt-3">
            {{ successMessage }}
          </div>

          <!-- Display update form validation errors -->
          <div v-if="updateFormErrors.length" class="alert alert-danger mt-3">
            <p v-for="error in updateFormErrors" :key="error">{{ error }}</p>
          </div>

          <!-- Submit Button -->
          <button type="submit" class="btn btn-primary" :disabled="isLoading">
            <span
              v-if="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="!isLoading">Update User Profile Data</span>
          </button>
        </form>
      </div>

      <br />

      <!-- Affiliations Form -->
      <div v-if="json_data" class="form-container">
        <form @submit.prevent="submitAffiliationsUpdate" class="edit-form">
          <h3>Affiliations</h3>
          <div class="input-group search-affiliations">
            <input
              type="text"
              v-model="searchQuery"
              @input="searchAffiliations"
              @focus="isFocused = true"
              @blur="handleBlur"
              class="form-control"
              placeholder="Start typing to search for affiliations..."
            />
            <div
              v-if="suggestions.length && isFocused"
              class="suggestions-list"
            >
              <div
                v-for="suggestion in suggestions"
                :key="suggestion.id"
                @mousedown.prevent="addAffiliation(suggestion)"
                class="suggestion-item"
              >
                {{ suggestion.organizationName }}
              </div>
            </div>
          </div>

          <!-- List of Current Affiliations -->
          <div class="current-affiliations-container">
            <h4>Current Organization/Lab Affiliations</h4>
            <div class="affiliations-list">
              <div
                v-for="(affiliation, index) in json_data.affiliations"
                :key="index"
                class="affiliation-item"
              >
                <span class="affiliation-name">{{
                  affiliation.organizationName
                }}</span>
                <button
                  @click="deleteAffiliation(index)"
                  class="delete-affiliation-btn"
                  type="button"
                >
                  X
                </button>
              </div>
            </div>
          </div>

          <br />

          <!-- Success Message Box -->
          <div v-if="updateSuccess === true" class="alert alert-success">
            {{ feedbackMessage }}
          </div>

          <!-- Error Message Box -->
          <div v-if="updateSuccess === false" class="alert alert-danger">
            {{ feedbackMessage }}
          </div>

          <!-- Submit Button for Affiliations Form -->
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="isLoadingAffiliations"
          >
            <span
              v-if="isLoadingAffiliations"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="!isLoadingAffiliations">Update Affiliations</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
//http://localhost:8082/edit/person?key=1501568961
import Api from "@/services/Api";
import { mapGetters } from "vuex";
import PostsService from "@/services/PostsService";

export default {
  name: "PersonEditDetail",
  components: {},
  data() {
    return {
      base_url: "https://tair-data.s3-us-west-2.amazonaws.com/prod/person/",
      json_data: null,
      formErrors: [],
      loading: false,
      loadingError: false,
      entries: [],
      phoneTypes: ["mobile", "fax", "work", "lab"],
      successMessage: "",
      successMessage2: "",
      isLoading: false,
      accountFormErrors: [],
      updateFormErrors: [],
      passwordError: "",
      confirmPasswordError: "",
      affiliations: [],
      newAffiliationName: "",
      searchQuery: "",
      suggestions: [],
      isLoadingAffiliations: false,
      successMessageAffiliations: "",
      isFocused: false,
      deletedAffiliations: [],
      updateSuccess: null, // true for success, false for error, null when no message should be displayed
      feedbackMessage: "",
      sendEmail: false,
    };
  },

  async mounted() {
    let jsonId = this.$route.query.key; // Adjust the key as per your routing setup
    if (jsonId) {
      try {
        let response = await PostsService.getPersonDetail({ key: jsonId });
        this.json_data = response.data;
        this.sendEmail = this.json_data.sendEmail === 'T';

        this.loading = true;
        this.loading = false;
        document.title = `Edit Person`;
      } catch (error) {
        console.error("Error fetching Person details: ", error);
        this.loadingError = true;
      }
    }
    this.normalizePhones();
  },
  computed: {
    formattedAddress: {
      get() {
        if (!this.json_data.address) return "";
        // Display the address with newlines in the textarea
        return this.json_data.address.replace(/\\/g, "\n");
      },
      set(value) {
        if (value) {
          // Convert newlines back to backslashes before saving
          this.json_data.address = value.replace(/\n/g, "\\");
        }
      },
    },
    isFormValid() {
      // Safely check if the properties exist
      const password =
        this.json_data && this.json_data.password
          ? this.json_data.password
          : "";
      const passwordConfirm =
        this.json_data && this.json_data.password_confirm
          ? this.json_data.password_confirm
          : "";
      // Check if both password fields are empty
      const bothEmpty = !password && !passwordConfirm;
      // Check if the passwords are the same and within the required length
      const passwordsValid =
        password === passwordConfirm &&
        password.length >= 6 &&
        password.length <= 10;
      // The form is valid if both fields are empty or if the passwords are valid
      return bothEmpty || passwordsValid;
    },
  },
  watch: {
    "json_data.password"(newVal) {
      if (this.json_data) {
        this.validatePasswords();
      }
    },
    "json_data.password_confirm"(newVal) {
      if (this.json_data) {
        this.validatePasswords();
      }
    },
  },
  methods: {
    ...mapGetters({
      getCommunityId: "authProfile/getCommunityId",
      getCredentials: "authProfile/getCredentials",
    }),
    normalizePhones() {
      //only do this if it exists
      if(this.json_data.phones){
        const normalizedPhones = {};
        // Convert array to an object for easier access
        this.json_data.phones.forEach((phone) => {
          normalizedPhones[phone.phoneType] = phone.phoneNumber;
        });

        // Ensure all phone types are accounted for
        this.phoneTypes.forEach((type) => {
          if (!normalizedPhones[type]) {
            normalizedPhones[type] = ""; // Add missing phone type with empty number
          }
        });

        // Convert back to array format if needed, or adjust usage as per your requirement
        this.json_data.phones = Object.entries(normalizedPhones).map(
          ([type, number]) => ({
            phoneType: type,
            phoneNumber: number,
          })
        );
      }
    },

    validatePasswords() {
      // Directly access json_data properties with fallbacks to handle uninitialized states
      const password = this.json_data ? this.json_data.password || "" : "";
      const passwordConfirm = this.json_data
        ? this.json_data.password_confirm || ""
        : "";

      // Reset error messages
      this.passwordError = "";
      this.confirmPasswordError = "";

      // Validation logic remains unchanged
      if (
        password.length > 0 &&
        (password.length < 6 || password.length > 10)
      ) {
        this.passwordError = "Password must be between 6 and 10 characters.";
        return;
      }
      if (password !== passwordConfirm) {
        this.confirmPasswordError = "Passwords do not match.";
      }
    },

    async editAccount() {
      this.isLoading = true;
      this.accountFormErrors = [];

      // Prepare account data
      if (!this.json_data.password) {
        this.json_data.password = "";
        this.json_data.password_confirm = "";
      }
      const { credentialId, secretKey } = this.getCredentials();
      const accountData = {
        credentialId: credentialId,
        secretKey: secretKey,
        communityId: this.json_data.communityId,
        email: this.json_data.email,
        userName: this.json_data.userName,
        password: this.json_data.password,
      };
      try {
        // Perform API call for account creation
        const response = await Api().put(
          "user/update-credentials",
          accountData
        );
        if (response.status === 200) {
          this.successMessage2 = "Person credentials updated successfully.";
          setTimeout(() => {
            this.$router.push({
              path: `/person`,
              query: { key: accountData.communityId.toString() },
            });
            this.successMessage = "";
          }, 2000); // 1000 milliseconds = 1 second
        } else {
          // Handle non-successful response
          throw new Error(`Failed to create account: ${response.status}`);
        }
      } catch (error) {
        this.isLoading = false;
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 600
        ) {
          const message =
            error.response.data.error ||
            "An unexpected error occurred. Please try again.";
          this.accountFormErrors.push(message);
        } else {
          this.accountFormErrors.push(
            "Failed to update details due to a network or server issue. Please try again."
          );
        }
      }
    },
    async updatePersonDetails() {
      this.isLoading = true;
      this.updateFormErrors = [];
      try {
        // Assuming the backend endpoint is '/update-person' and expects a PUT request
        const phones =
          this.json_data && this.json_data.phones ? this.json_data.phones : [];
        const workPhone = phones.find((phone) => phone.phoneType === "work")
          ? phones.find((phone) => phone.phoneType === "work").phoneNumber
          : "";
        const faxPhone = phones.find((phone) => phone.phoneType === "fax")
          ? phones.find((phone) => phone.phoneType === "fax").phoneNumber
          : "";
        const mobilePhone = phones.find((phone) => phone.phoneType === "mobile")
          ? phones.find((phone) => phone.phoneType === "mobile").phoneNumber
          : "";
        const labPhone = phones.find((phone) => phone.phoneType === "lab")
          ? phones.find((phone) => phone.phoneType === "lab").phoneNumber
          : "";

        const updatedPersonDetails = {
          communityId: this.json_data.communityId,
          //Person Table
          firstName: this.json_data.firstName,
          lastName: this.json_data.lastName,
          institutionName: this.json_data.institutionName,
          jobTitle: this.json_data.primaryJobTitle,
          sendEmail: this.sendEmail ? "T" : "F",
          //Phone Table
          workPhone: workPhone,
          faxPhone: faxPhone,
          mobilePhone: mobilePhone,
          labPhone: labPhone,
          //Address Table
          streetAddress: this.json_data.address, // Assuming 'address' is stored directly in json_data
          country: this.json_data.country,
          stateProvince: this.json_data.region,
          city: this.json_data.city,
          zipcode: this.json_data.postalCode,
        };

        const response = await Api().put(
          "user/update-person",
          updatedPersonDetails
        );
        this.isLoading = false;

        // Check for success response status (e.g., 200 OK)
        if (response.status === 200) {
          this.successMessage = "Person details updated successfully.";
          setTimeout(() => {
            this.$router.push({
              path: `/person`,
              query: { key: updatedPersonDetails.communityId.toString() },
            });
            this.successMessage = "";
          }, 2000); // 1000 milliseconds = 1 second
        } else {
          // Handle non-successful responses
          throw new Error(`Failed to update database: ${response.status}`);
        }
      } catch (error) {
        this.isLoading = false;
        console.error("Error updating person details:", error);
        // Update UI to show error message
        this.updateFormErrors.push(
          "Failed to update details. Please try again."
        );
      }
    },
    async searchAffiliations() {
      if (this.searchQuery.trim() === "") {
        this.suggestions = [];
        return;
      }

      // Replace this with an actual API call
      const results = await this.fetchAffiliations(this.searchQuery);
      this.suggestions = results;
    },

    async fetchAffiliations(query) {
      const response = await Api().get("labs/search", {
        params: { labName: query },
      });
      if (response.status === 200 && response.data.rows) {
        this.suggestions = response.data.rows.map((lab) => ({
          id: lab.ORGANIZATION_ID,
          organizationName: lab.NAME,
          organizationId: lab.ORGANIZATION_ID,
        }));
        return this.suggestions;
      } else {
        this.suggestions = []; // Clear suggestions if no results
      }
    },

    handleBlur() {
      // Use a timeout to delay hiding the dropdown, allowing click events to process
      setTimeout(() => {
        this.isFocused = false;
      }, 100); // Adjust delay as necessary
    },

    addAffiliation(suggestion) {
      if (
        this.deletedAffiliations &&
        this.deletedAffiliations.findIndex(
          (affiliation) => affiliation.id === suggestion.id
        ) !== -1
      ) {
        // Remove from deletedAffiliations if it was previously marked for deletion
        this.deletedAffiliations.splice(deletedIndex, 1);
      }
      if (
        this.affiliations &&
        !this.affiliations.some(
          (affiliation) => affiliation.id === suggestion.id
        )
      ) {
        this.affiliations.push(suggestion);
      }
      if (!this.json_data.affiliations) {
        this.json_data.affiliations = [];
        this.json_data.affiliations.push(suggestion);
      } else {
        if (
          this.json_data &&
          this.json_data.affiliations &&
          !this.json_data.affiliations.some(
            (affiliation) => affiliation.id === suggestion.id
          )
        ) {
          this.json_data.affiliations.push(suggestion);
        }
      }
      // Reset input and close suggestions dropdown
      this.searchQuery = "";
      this.suggestions = [];
    },

    deleteAffiliation(affiliation) {
      const indexInAffiliations = this.affiliations.findIndex(
        (a) => a.id === affiliation.id
      );

      if (indexInAffiliations !== -1) {
        this.affiliations.splice(indexInAffiliations, 1);
      }

      const indexInJsonData = affiliation;

      if (indexInJsonData !== -1) {
        this.deletedAffiliations.push(this.json_data.affiliations[affiliation]);

        this.json_data.affiliations.splice(indexInJsonData, 1);
      }
    },

    async insertAffiliations(affiliations) {
      // Example POST request to your backend
      const response = await Api().post("user/insert-affiliations", {
        affiliations: this.affiliations,
        communityId: this.json_data.communityId,
      });
      return response.data;
    },

    async deleteAffiliations(deletedAffiliations) {
      // Example POST request for deletion. Adjust according to your API.
      const response = await Api().post("user/delete-affiliations", {
        deletedAffiliations: this.deletedAffiliations,
        communityId: this.json_data.communityId,
      });
      return response.data;
    },

    async submitAffiliationsUpdate() {
      this.updateSuccess = null; // Reset feedback state
      try {
        if (this.affiliations.length > 0) {
          await this.insertAffiliations(this.affiliations);
        }
        if (this.deletedAffiliations.length > 0) {
          await this.deleteAffiliations(this.deletedAffiliations);
        }
        this.affiliations = [];
        this.deletedAffiliations = [];

        // Update feedback message and state for success
        this.updateSuccess = true;
        this.feedbackMessage = "Affiliations updated successfully.";

        setTimeout(() => {
          this.$router.push({
            path: `/person`,
            query: { key: this.json_data.communityId.toString() },
          });
          this.successMessage = "";
        }, 2000); // 1000 milliseconds = 1 second
      } catch (error) {
        console.error("Failed to update affiliations:", error);

        // Update feedback message and state for failure
        this.updateSuccess = false;
        this.feedbackMessage =
          "Failed to update affiliations. Please try again.";
      }
    },
  },
};
</script>

<style scoped>
/* Apply the same styling rules that you used on the other pages */
.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  height: 700px;
  overflow-y: scroll;
}
.list-wrapper {
  width: 100%;
  max-width: 800px; /* Max width for the forms */
  margin: 0 auto; /* Center the list wrapper */
  overflow-y: auto; /* Add scroll to the entire page */
  padding: 20px; /* Padding for aesthetic spacing */
  box-sizing: border-box; /* Include padding and border in the width */
}

.edit-form {
  border: 1px solid #ccc;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin: auto;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.input-group input,
.input-group textarea,
.input-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-group.checkbox-inline {
  display: flex;
  align-items: center; /* Vertically center align the label and checkbox */
  vertical-align: center;
  justify-content: flex-start; /* Align items to the start of the container */
  padding-top: 10px;
  padding-bottom: 10px;
}

.input-group.checkbox-inline .checkbox-label {
  margin-left: 10px; /* Or any space you see fit */
  vertical-align: top;
}
.input-group.checkbox-inline input[type="checkbox"] {
  width: auto; /* Remove full width from checkboxes */
  transform: scale(2.5);
  vertical-align: top;
}

/* Responsive adjustments as needed */
@media (max-width: 768px) {
  .form-container {
    width: 90%;
  }
}

.error-message {
  color: #dc3545; /* Red for errors */
  /* Add other styling as needed */
}

.current-affiliations-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.current-affiliations-container h4 {
  margin-bottom: 15px;
  color: #333;
  font-size: 18px;
}

.affiliations-list {
  display: flex;
  flex-direction: column;
}

.affiliation-item {
  padding: 10px;
  background-color: #ffffff;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.affiliation-item:last-child {
  margin-bottom: 0;
}

.affiliation-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.affiliation-name {
  display: block;
  font-weight: 500;
  color: #0056b3;
}

.delete-affiliation-btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #cc0000;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.affiliation-item {
  position: relative;
  display: flex;
  align-items: center;
  /* Other styles remain the same */
}

.search-affiliations {
  position: relative;
  display: inline-block; /* or 'block' depending on your layout */
}

.suggestions-list {
  position: absolute;
  top: 100%; /* Position directly below the input field */
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
}
.suggestions-list {
  z-index: 1000; /* Arbitrarily high to ensure it's on top */
}

.suggestion-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.suggestion-item:last-child {
  border-bottom: none;
}

.suggestion-item:hover {
  background-color: #f5f5f5;
}
.alert-success {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}
</style>
