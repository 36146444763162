<template>
  <div class="items-panel">
    <img
      v-if="contentType === 'image'"
      :src="contentUrl"
      alt="Loaded Content"
    />
    <pre v-else-if="contentType === 'text'">{{ textContent }}</pre>
    <iframe
      v-else-if="contentType === 'pdf'"
      :src="contentUrl"
      frameborder="0"
    ></iframe>
    <div v-if="loaded && !contentUrl && !textContent">
      Cannot load file content. Please try again later or contact
      <a href="mailto:curator@arabidopsis.org">curator@arabidopsis.org</a>
    </div>
  </div>
</template>

<script>


export default {
  name: "DownloadFile",
  data() {
    return {
      contentUrl: this.$route.query.contentUrl || null,
      textContent: this.$route.query.textContent || null,
      contentType: this.$route.query.contentType || '',
      loaded: false,
    };
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
.icon-text-wrapper {
  display: flex;
  align-items: center;

  // Ensure icons align nicely with the text
  .margin-right {
    margin-right: 10px; // Adjust spacing between icon and text as needed
  }

  h3,
  a {
    display: inline; // Ensure text does not break onto a new line
    margin: 0; // Remove any default margins that might cause layout issues
  }
}

iframe {
  width: 100%;
  height: 600px;
  border: none; /* Remove default border */
}
</style>
