<template>
  <div class="items-panel">
    <div v-for="(item, key) in items" :key="item.name" class="download-item">
      <div v-if="item.type == 'dir'" class="folder-display">
        <span class="icon-text-wrapper">
          <i class="fa-solid fa-folder"></i>
          <a :href="item.path"
            ><h3>{{ item.name }}</h3></a
          >
        </span>
      </div>
      <div v-else class="file-display">
        <span class="icon-text-wrapper">
          <i class="fa-solid fa-file"></i>
          <a :href="item.path" @click.prevent="checkAccess(item.path, false)">{{ item.name }}</a>
        </span>
        <span class="item-metadata">{{ item.size }}</span>
        <span class="item-metadata">{{ item.lastModified }}</span>
        <span class="icon-text-wrapper">
          <button class="mybtn" @click="checkAccess(item.path)" :download="item.name"
            ><i class="fa-solid fa-download"></i
          ></button>
        </span>
      </div>
    </div>
    <div v-if="loaded && (!items || items.length == 0)">
      Cannot load directory content. Please try again later or contact
      <a href="mailto:curator@arabidopsis.org">curator@arabidopsis.org</a>
    </div>
  </div>
</template>

<script>
import Api from "@/services/Api";
import { listDirContent } from "@/services/DownloadService";

export default {
  name: "DownloadList",
  data() {
    return {
      items: [],
      loaded: false,
    };
  },
  mounted() {
    this.fetchItems();
  },
  watch: {
    // Reactively watch for route changes, including query params
    "$route.query.dir": {
      immediate: true,
      async handler(newDir, oldDir) {
        await this.fetchItems();
      },
    },
  },
  methods: {
    async fetchItems() {
      if (this.$route.query.dir) {
        const items = await listDirContent(this.$route.query.dir);
        if (!items || items.length == 0) {
          // do nothing
        } else {
          this.items = items;
        }
      }
      this.loaded = true;
    },
    downloadBlob(filePath, blob) {
      const link = document.createElement("a");
      link.style.display = "none";
      
      const url = URL.createObjectURL(blob);
      link.href = url;
      const fileName = filePath.split("/").at(-1);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    async checkAccess(filePath, isDownload=true) {
      try {
        const response = await Api().get(`download-files/download?filePath=${filePath}`);

        if (response.status === 200) {
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const contentType = blob.type;
          console.log(contentType, isDownload);
          if(isDownload) {
            this.downloadBlob(filePath, blob);
          } else {
            if (contentType.startsWith("text/")) {
              const textContent = await blob.text();
              this.$router.push({
                name: 'DownloadFile',
                query: { filePath, contentType: 'text', textContent }
              });
            } else if (contentType.startsWith("image/")) {
              const contentUrl = URL.createObjectURL(blob);
              this.$router.push({
                name: 'DownloadFile',
                query: { filePath, contentType: 'image', contentUrl }
              });
            } else if (contentType === "application/pdf") {
              const contentUrl = URL.createObjectURL(blob);
              this.$router.push({
                name: 'DownloadFile',
                query: { filePath, contentType: 'pdf', contentUrl }
              });
            } else {
              this.downloadBlob(filePath, blob);
            }
          }
        }
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content-wrapper {
  .items-panel {
    .item-metadata {
      margin-left: 10px
    }
    .margin-right {
      margin-right: 10px
    }
    .file-display {
      display: flex;
    }
    .icon-text-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      i {
        margin-right: 10px;
      }
      .fa-folder {
        padding-bottom: 10px;
      }
      .fa-file {
        padding-bottom: 3px;
      }
      .fa-download {
        margin-left: 10px
      }
    }
  }
}
.mybtn {
  background-color: transparent;
  border: 0;
}
</style>
