<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
        <div class="d-flex align-items-center">
          <h4>Transposon: {{ json_data.name }}</h4>
          <div class="star-container ml-2 mb-2">
              <span class="star"> ★</span>
              <span class="premium-text">Premium Page</span>
            </div>
          </div>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/transposon?key=1
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
import { getMapElementViewUrl } from "@/services/JBrowseService";

export default {
  name: "TransposonDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "transposon/",
      json_data: null,
      side_cols: ["Summary"],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {},
    };
  },

  async mounted() {
    document.title = "Transposon Detail";
    let transposonId = this.$route.query.key;
    if (transposonId) {
      try {
        let response = await PostsService.getTransposonDetail({
          key: transposonId,
        });
        this.json_data = response.data;

        this.loading = true;
        this.processEntries();
        this.loading = false;
        document.title = `Transposon Detail`;
      } catch (error) {
        console.error("Error fetching Transposon details: ", error);
        this.loading = false;
        this.error = true;
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  },
  methods: {
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.name,
      });
      bands.push({
        key: "Date Last Modified",
        text: this.dateToYMD(this.json_data.dateLastModified),
      });
      bands.push({
        key: "Chromosome",
        text: this.json_data.chromosome,
      });
      bands.push({
        key: "Family Name",
        text: this.json_data.familyName,
        type: "link",
        link: `transposonfamily?key=${this.json_data.familyId}`,
      });
      bands.push({
        key: "Super Family Name",
        text: this.json_data.superFamilyName
          ? this.json_data.superFamilyName
          : "N/A",
      });

      bands.push({
        key: "Nucleotide Sequence",
        text: this.json_data.sequence.nucleotideSequenceType,
        type: "link",
        link: `/sequence?key=${this.json_data.sequence.nucleotideSeqId}`,
        helpTxt:
          "One or more nucleotide sequences associated to a sequenced object such as a gene, clone, marker or polymorphism.",
      });
      bands.push({
        key: "Associated Transposable Element Genes",
        text:
          this.json_data.associatedTransposable &&
          this.json_data.associatedTransposable.length > 0
            ? this.json_data.associatedTransposable[0].locusName
            : "N/A",
        type: "link",
        link:
          this.json_data.associatedTransposable &&
          this.json_data.associatedTransposable.length > 0
            ? `/locus?key=${this.json_data.associatedTransposable[0].locusId}`
            : null,
      });
      bands.push(this.getFeatures());
      bands.push(this.getMapLocations());
      bands.push(this.getMapLinks());
      return bands;
    },
    getFeatures() {
      let entry = {
        key: "Transposon Feature",
        type: "table",
        items: [],
        fields: [],
      };

      // Define table fields
      entry.fields.push({
        key: "type",
        label: "type",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "coordinates",
        label: "coordinates",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "annot",
        label: "annotation source	",
        cellType: "name_link",
      });
      entry.fields.push({ key: "date", label: "date", cellType: "name_link" });
      let features = this.json_data.transposonFeature;
      if (!features || features.length === 0) {
        entry.type = "";
        entry.text = "No Transposon Features available";
        return entry;
      }
      features.forEach((feature) => {
        entry.items.push({
          type: { name: feature.assignmentFeatureType },
          coordinates: {
            name: `${feature.startPosition} - ${feature.endPosition}`,
          },
        });
      });
      return entry;
    },
    getMapLocations() {
      let entry = {
        key: "Map Locations",
        type: "table",
        items: [],
        fields: [],
      };

      // Define table fields
      entry.fields.push({
        key: "chrom",
        label: "Chromosome",
        cellType: "name_link",
      });
      entry.fields.push({ key: "map", label: "Map", cellType: "name_link" });
      entry.fields.push({
        key: "mapType",
        label: "Map Type",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "startEnd",
        label: "coordinates",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "orientation",
        label: "Orientation",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "attrib",
        label: "Attrib",
        cellType: "name_link",
      });

      let mapLocations = this.json_data.globalAssignment; // Adjust the path according to your data structure
      if (!mapLocations || mapLocations.length === 0) {
        entry.type = "";
        entry.text = "No Map Locations available";
        return entry;
      }

      // remove duplicate map types
      mapLocations = mapLocations.filter(
        (v, i, a) => a.findIndex((t) => t.mapType === v.mapType) === i
      );
      entry.items = mapLocations.map((loc) => {
        let orientation = "";
        if (loc.orientation == "F") {
          orientation = "forward";
        } else if (loc.orientation == "R") {
          orientation = "reverse";
        } else {
          orientation = "unknwon";
        }
        let mapLinkType = "map";
        if (loc.mapType == "assembly_unit") {
          mapLinkType = "assembly_unit";
        }
        return {
          chrom: { name: loc.chromosome },
          map: {
            name: loc.name,
            link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=${mapLinkType}&id=${loc.mapId}`,
          },
          mapType: { name: loc.mapType },
          startEnd: {
            name: `${loc.startPosition} - ${loc.endPosition} ${loc.units}`,
          },
          orientation: { name: orientation },
          attrib: loc.attributionIdForLink
            ? {
                name: "details",
                link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?id=${loc.attributionIdForLink}&type=global_assignment`, // Adjust link format as needed
              }
            : { name: "" },
        };
      });

      return entry;
    },
    getMapLinks() {
      let entry = {
        key: "Map Links",
        type: "link_list",
        items: [],
      };
      let mapLocations = this.json_data.globalAssignment;
      if (!mapLocations) {
        return {
          key: "Map Links",
          text: "No Map Links Available",
          type: "",
        };
      }
      let agiMapLocation = mapLocations.find((loc) => loc.name == "AGI");

      if (!agiMapLocation)
        return {
          key: "Map Links",
          text: "No Map Links Available",
          type: "",
        };
      entry.items.push({
        text: "JBrowse",
        link: getMapElementViewUrl(
          agiMapLocation.chromosome,
          agiMapLocation.startPosition,
          agiMapLocation.endPosition,
          "transposon"
        ),
      });
      return entry;
    },
    //Getters
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}


</style>
