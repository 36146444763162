<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
        <div class="d-flex align-items-center">
          <h4>Keyword: {{ json_data.summary.kw }}</h4>
          <div class="star-container ml-2 mb-2">
              <span class="star"> ★</span>
              <span class="premium-text">Premium Page</span>
            </div></div>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/keyword?key=1
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
export default {
  name: "KeywordDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      loading: false,
      error: false,
      base_url: process.env.VUE_APP_S3_PATH + "keyword/",
      json_data: null,
      side_cols: ["Summary"],
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {
        keyword: `<b>keyword:</b> The controlled vocabulary term.`,
        keywordId: `<b>external keyword ID:</b> The unique primary identifier for a keyword. GO IDs correspond to the primary ID used by the Gene Ontology Consortium.`,
        dataToTerm: `<b>Associated data:</b> Data that are specifically associated with the term are displayed according to their type. For example a GO molecular function that is used to describe a gene product is also associated to the paper that presents the experimental assay for the function.Clicking on any of the links will display a full list of the given data associated to the term.`,
        dataToChildren: `<b>Associated data to children:</b> Data that are specifically associated with the children of a parent term being displayed. For example, the term "photosynthesis" has "light reactions" and "dark reactions" as its children. Therefore, one chose to see all genes annotated to the process "photosynthesis" or only those genes involved in the light reactions.Clicking on any of the links will display a full list of the children terms and the data annotated to them.`,
        treeview: `<b>Keyword TreeViewer:</b> The TreeViewer displays keywords used in TAIR in a browseable format. Keywords are organized into sets of controlled vocabularies representing a variety of biological domains. Each domain is represented as directed acyclic graphs (DAGs).This means a keyword may have one or more parent. For example posttranscriptional gene silencing has two parent terms: gene silencing and regulation of gene expression, epigenetic. The browser can also be configured to display a specific type of data associated for each keyword, such as the number of associated genes or publications.`,
        synonyms: `<b>Synonym</b>: Keyword synonyms have the same meaning as a keyword. Searching for keyword will include all synonyms in the search.`,
      },
    };
  },
  async mounted() {
    let keywordId = this.$route.query.key;
    if (keywordId) {
      try {
        let response = await PostsService.getKeywordDetail({ key: keywordId });
        this.json_data = response.data;
        this.processEntries();
        document.title = `Keyword: ${this.json_data.summary.kw}`;
      } catch (error) {
        console.error("Error fetching keyword details: ", error);
        this.loading = false;
        this.error = true;
      }
    }
  },
  methods: {
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        var top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      let scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.summary.kw,
        helpTxt: this.helpTexts.keyword,
      });
      bands.push({
        key: "Synonyms",
        text: this.json_data.synonyms
          ? this.json_data.synonyms.join(",")
          : "No Synonyms",
        helpTxt: this.helpTexts.synonyms,
      });
      bands.push({
        key: "Date Last Modified",
        text: this.dateToYMD(this.json_data.summary.dateLastModified),
      });
      bands.push({
        key: "Definition",
        text: this.json_data.summary.kwDef,
      });
      bands.push({
        key: "External ID",
        type: this.getLinkToKeyword() ? "link" : null,
        text: this.json_data.summary.extdbId,
        link: this.getLinkToKeyword(),
        helpTxt: this.helpTexts.keywordId,
      });
      bands.push({
        key: "Keyword Category",
        text: this.json_data.summary.kwType,
      });

      bands.push(this.getDataToTerm());

      bands.push(this.getDataToChildren());

      let browserLink = ``;
      if (this.json_data.summary.extdbId) {
        let gopoId = this.json_data.summary.extdbId;
        if (gopoId.startsWith("GO:")) {
          browserLink = `https://amigo.geneontology.org/amigo/term/${gopoId}#display-lineage-tab`;
        } else if (gopoId.startsWith("PO:")) {
          browserLink = `https://browser.planteome.org/amigo/term/${gopoId}#display-lineage-tab`;
        } else {
          browserLink = null;
        }
      }
      if (browserLink != null) {
        bands.push({
          key: "TreeView",
          type: "link",
          text: "Keyword TreeViewer",
          link: browserLink,
          helpTxt: this.helpTexts.treeview,
        });
      }

      //   bands.push({
      //     key: "Similarly Annotated Genes From Other Organisms",
      //     type: "link",
      //     text: "GO Database",
      //     link: `https://amigo.geneontology.org/amigo/term/${this.json_data.summary.extdbId}`,
      //   });

      return bands;
    },
    getLinkToKeyword() {
      let linkId = this.json_data.summary.extdbId;
      if (linkId.startsWith("GO:")) {
        return `https://amigo.geneontology.org/amigo/term/${linkId}`;
      } else if (linkId.startsWith("PO:")) {
        return `https://browser.planteome.org/amigo/search/ontology?q=${linkId}`;
      } else {
        return null;
      }
    },
    getDataToTerm() {
      let entry = {
        key: "Data Associated To This Term",
        type: "table",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.dataToTerm,
      };
      if (this.json_data.dataToTerm == null) {
        entry.type = null;
        entry.text = "No data associated to this term.";
        return entry;
      }
      // entry.fields.push({
      //   key: "genes",
      //   label: "Genes",
      //   cellType: "name_link",
      // });
      entry.fields.push({
        key: "publications",
        label: "Publications",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "annotations",
        label: "Annotations",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "loci",
        label: "Loci",
        cellType: "name_link",
      });
      let keywordId = this.$route.query.key;
      // let genes = this.json_data.dataToTerm.filter(
      //   (e) => e.displayName == "genes"
      // );
      let publications = this.json_data.dataToTerm.filter(
        (e) => e.displayName == "publications"
      );
      let annotations = this.json_data.dataToTerm.filter(
        (e) => e.displayName == "annotations"
      );
      let loci = this.json_data.dataToTerm.filter(
        (e) => e.displayName == "loci"
      );
      let item = {};
      if (publications.length > 0) {
        let searchParams = {
          keywordTerms: [
            {
              keyword_method: "exactly",
              keyword_term: this.json_data.summary.kw,
            },
          ],
        };
        let encodedParams = encodeURIComponent(JSON.stringify(searchParams));
        const link = `/results?mainType=detail&category=publication&search_params=${encodedParams}`;

        item.publications = {
          name: publications[0].linkCount,
          link: link,
        };
      }
      if (annotations.length > 0) {
        let searchParams = {
          keywordExact: this.json_data.summary.kw,
        };
        let encodedParams = encodeURIComponent(JSON.stringify(searchParams));
        const link = `/results?mainType=detail&category=annotations&search_params=${encodedParams}`;
        item.annotations = {
          name: annotations[0].linkCount,
          link: link,
        };
      }
      if (loci.length > 0) {
        let searchParams = {
          keywordTerm: this.json_data.summary.kw,
          keywordExact: this.json_data.summary.kw,
        };
        let encodedParams = encodeURIComponent(JSON.stringify(searchParams));
        const link = `/results?mainType=detail&category=genes&search_params=${encodedParams}`;
        item.loci = {
          name: loci[0].linkCount,
          link: link,
        };
      }
      entry.items.push(item);
      return entry;
    },
    getDataToChildren() {
      let entry = {
        key: "Data Associated To Children Terms",
        type: "table",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.dataToChildren,
      };
      if (this.json_data.dataToChildren == null) {
        entry.type = null;
        entry.text = "No data associated to this term.";
        return entry;
      }
      // entry.fields.push({
      //   key: "genes",
      //   label: "Genes",
      //   cellType: "name_link",
      // });
      entry.fields.push({
        key: "publications",
        label: "Publications",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "annotations",
        label: "Annotations",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "loci",
        label: "Loci",
        cellType: "name_link",
      });
      let keywordId = this.$route.query.key;
      // let genes = this.json_data.dataToChildren.filter(
      //   (e) => e.displayName == "genes"
      // );
      let publications = this.json_data.dataToChildren.filter(
        (e) => e.displayName == "publications"
      );
      let annotations = this.json_data.dataToChildren.filter(
        (e) => e.displayName == "annotations"
      );
      let loci = this.json_data.dataToChildren.filter(
        (e) => e.displayName == "loci"
      );
      let item = {};
      if (publications.length > 0) {
        let searchParams = { keywordChild: this.json_data.summary.kw };
        let encodedParams = encodeURIComponent(JSON.stringify(searchParams));
        let link = `/results?mainType=detail&category=publication&search_params=${encodedParams}`;
        item.publications = {
          name: publications[0].linkCount,
          link: link,
        };
      }
      if (annotations.length > 0) {
        let searchParams = { keywordChild: this.json_data.summary.kw };
        let encodedParams = encodeURIComponent(JSON.stringify(searchParams));
        let link = `/results?mainType=detail&category=annotations&search_params=${encodedParams}`;
        item.annotations = {
          name: annotations[0].linkCount,
          link: link,
        };
      }
      if (loci.length > 0) {
        let searchParams = { keywordChild: this.json_data.summary.kw };
        let encodedParams = encodeURIComponent(JSON.stringify(searchParams));
        let link = `/results?mainType=detail&category=genes&search_params=${encodedParams}`;
        item.loci = {
          name: loci[0].linkCount,
          link: link,
        };
      }
      entry.items.push(item);
      return entry;
    },
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}


</style>
