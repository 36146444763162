import PostsService from "../PostsService";

export async function loadAnnotationsResults(search_params, paid=true) {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    {
      key: "locus1",
      label: "Locus",
      cellType: "name_link",
    },
    {
      key: "repgene",
      label: "Gene Model",
      cellType: "name_link",
    },
    {
      key: "gene_name",
      label: "Gene Symbol/Full Name",
      cellType: "name_link",
    },
    {
      key: "relationshipType",
      label: "Relationship Type",
      cellType: "name_link",
    },
    {
      key: "keyword",
      label: "Keyword",
      cellType: "name_link",
    },
    {
      key: "cat",
      label: "Keyword Category",
      cellType: "name_link",
    },
    {
      key: "evidence",
      label: `Evidence:Description:With`,
      cellType: "html",
    },
    {
      key: "reference",
      label: "Reference",
      cellType: "name_link",
    },
    {
      key: "dateLastModified",
      label: "date last modified",
      cellType: "name_link",
    },
    {
      key: "annotated_by",
      label: "Annotated By",
      cellType: "name_link",
    },
  ];

  let items = [];
  try {
    let response = await PostsService.fetchAnnotationsDetailSearch(
      search_params, paid
    );
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let docs = response.data.docs;

    // console.log(response);
    for (const d of docs) {
      let dateLastModified = d.dateLastModified ? d.dateLastModified : "";
      //convert date to YYYY-MM-DD format
      if (dateLastModified) {
        let date = new Date(dateLastModified);
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        dateLastModified = year + "-" + month + "-" + day;
      }
      let annotatedLink = `/person?key=${d.community_id}`;
      if (d.annotatedByType == "organization") {
        annotatedLink = `/organization?key=${d.community_id}`;
      }
      let evidence = d.evidenceCodeFull ? `<i>${d.evidenceCodeFull}:</i>` : "";
      if (d.description) {
        evidence += `<br />${d.description}:`;
      }
      if (d.evidenceWith) {
        evidence += `<br /><span style="width: 250px; display: inline-block; word-wrap: break-word;">${d.evidenceWith}</span>`;
      }
      let geneSymbol = "";
      if (d.symbol) {
        geneSymbol = d.symbol;
      }
      if (d.symbolFull) {
        geneSymbol += `/${d.symbolFull[0]}`;
      }

      let reference = {};
      if (d.authors) {
        reference = {
          name: `${d.authors.split(",")[0]} et al (${d.publicationYear[0]})`,
          link: d.reference_id ? `/publication?key=${d.reference_id}` : "",
        };
      } else if (d.analysisReferenceId) {
        reference = {
          name: `${d.analysisReferenceName.trim()}`,
          link: `/analysisreference?key=${d.analysisReferenceId}`,
        };
      } else if (d.communicationReferenceId) {
        reference = {
          name: `${d.communicationName}`,
          link: `/communication?key=${d.communicationReferenceId}`,
        };
      }
      let gene = null;
      if(d.geneId) {
        gene = {
          name: d.gene_name ? d.gene_name[0] : "",
          link: d.geneId ? `/gene?key=${d.geneId}` : ""
        }
      } else if(d.rep_geneId) {
        gene = {
          name: d.rep_gene_name ? d.rep_gene_name[0] : "",
          link: d.rep_geneId ? `/gene?key=${d.rep_geneId}` : ""
        }
      }
      items.push({
        id: d.id,
        selected: false,
        locus1: {
          name: d.locus_name ? d.locus_name[0] : "",
          link: d.locusId ? `/locus?key=${d.locusId}` : "",
        },
        repgene: gene,
        gene_name: {
          name: geneSymbol,
        },
        keyword: {
          name: d.keyword ? d.keyword_exact : "",
          link: d.keyword ? `/keyword?key=${d.keyword_id}` : "",
        },
        relationshipType: {
          name: d.relationshipType ? d.relationshipType : "",
        },
        cat: {
          name: d.keywordTypeFull ? d.keywordTypeFull[0] : "",
        },
        evidence: {
          value: evidence,
        },
        dateLastModified: {
          name: dateLastModified,
        },
        reference: reference,
        annotated_by: {
          name: d.annotatedBy ? d.annotatedBy : "",
          link: annotatedLink,
        },
        original: d,
      });
    }

    let totalMatch = response.data.total;
    search_params.matchCount = totalMatch;

    let queryParts = [];
    if (
      search_params.keywordExact ||
      search_params.keywordChild ||
      search_params.keywordCombo
    ) {
      let keyword = "";
      if (search_params.keywordExact) {
        keyword = search_params.keywordExact;
      } else if (search_params.keywordChild) {
        keyword = search_params.keywordChild;
      } else if (search_params.keywordCombo) {
        keyword = search_params.keywordCombo;
      }
      queryParts.push(
        `keywords are <span class="highlight-term">${keyword}</span>`
      );
    }
    let queryStatement = "Your query for annotations";
    if (queryParts.length > 0) {
      queryStatement += " where ";
      queryStatement += queryParts.join(" and ");
    }
    queryStatement += ` resulted in <span style="color: green">${search_params.matchCount}</span> records.`;
    return {
      error: false,
      items: items,
      fields: fields,
      search_params: search_params,
      searchResultsHeader: "Annotations",
      queryStatement,
    };
  } catch (error) {
    console.log(error);
    return { error: true, items: [], fields: [] };
  }
}
