<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6">
        <div class="card shadow">
          <div class="card-body p-5">
            <div v-if="!isLoading">
              <div v-if="isUserLoggedIn">
                <div v-if="!isOrcidLinked">
                  <h1 class="text-center mb-4 text-success">Welcome to TAIR</h1>
                  <!-- <p class="text-center text-muted mb-4">The Arabidopsis Information Resource</p> -->
                  
                  <div class="alert alert-info mb-4" role="alert">
                    <h4 class="alert-heading">ORCID Connection Required</h4>
                    <p class="mb-0">
                      <strong>Important Update:</strong> TAIR now requires users to connect their ORCID accounts to access TAIR services. This integration enhances our ability to provide personalized features and streamline scientific collaboration.
                    </p>
                    <hr>
                    <p class="mb-0">
                      Please click the button below to connect your ORCID account. If you don't have an ORCID account, you'll have the option to create one during the connection process.
                    </p>
                  </div>
          
                  <button 
                    @click="connectOrcid" 
                    class="btn btn-success btn-lg w-100 d-flex align-items-center justify-content-center"
                    :disabled="isConnecting"
                    style="height: 60px;"
                  >
                    <img src="/orcid.png" alt="ORCID logo" class="me-2" style="height: 40px; width: auto;" />
                    <span>{{ isConnecting ? 'Connecting...' : 'Connect ORCID for TAIR Access' }}</span>
                  </button>
          
                  <p v-if="errorMessage" class="text-danger mt-3 text-center">{{ errorMessage }}</p>
                </div>
                <div v-else>
                  <div class="text-center">
                    <i class="bi bi-check-circle text-success" style="font-size: 3rem;"></i>
                    <h2 class="mt-3">Welcome to TAIR!</h2>
                    <p class="text-muted">Your ORCID is successfully linked.</p>
                  </div>
                </div>
              </div>
              <!-- <div v-else>
                <div class="text-center">
                  <i class="bi bi-lock text-secondary" style="font-size: 3rem;"></i>
                  <h3 class="mt-3">Orcid Authentication Failed</h3>
                  <p class="text-muted">You are trying to login from an Orcid Account that is not linked to TAIR. Register by clicking below:</p>
                  <button @click="register" class="btn btn-primary text-white btn-lg w-100 mb-2">
                  New to TAIR? Register Here
                  </button>
                </div>
              </div> -->
              <div v-else>
                <div v-if="orcidAlreadyLinked">
                  <div class="text-center">
                      <i class="bi bi-lock text-secondary" style="font-size: 3rem;"></i>
                      <h3 class="mt-3">ORCID Account Already Linked</h3>
                      <p class="text-muted">
                          It looks like the ORCID account you're trying to link is already connected to another TAIR account. Please unlink it from the other account before linking it here. If you do not have access to that account, feel free to contact us at <a href="mailto:curator@arabidopsis.org">curator@arabidopsis.org</a>.
                      </p>
                  </div>
                </div>
                <div v-else>
                  <div class="text-center">
                    <i class="bi bi-lock text-secondary" style="font-size: 3rem;"></i>
                    <h3 class="mt-3">Orcid Authentication Failed</h3>
                    <p class="text-muted">You are trying to login from an Orcid Account that is not linked to TAIR. Register by clicking below:</p>
                    <button @click="register" class="btn btn-primary text-white btn-lg w-100 mb-2">
                      New to TAIR? Register Here
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-center">
              <div class="spinner-border text-success" role="status">
              </div>
              <p class="mt-3 text-muted">Loading...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
  import { mapGetters, mapActions, mapMutations } from "vuex";
  import { checkOrcidStatus, processOrcidCode, authenticateOrcid } from '../../services/orcidService';
  import Cookies from "js-cookie";

  export default {
    name: 'TAIROrcidLogin',
    data() {
      return {
        isOrcidLinked: false,
        partnerId: 'tair',
        isLoading: true,
        isUserLoggedIn: false,
        isConnecting: false,
        errorMessage: '',
        orcidId: '',
        userIdentifier: '',
        orcidAlreadyLinked: false,
      }
    },
    computed: {
      ...mapGetters({
        storeUserIdentifier: "authProfile/getCommunityId",
        isLoggedIn: "authProfile/isLoggedIn",
        orcidId: "authProfile/getOrcidId",
        // getCredentials: "authProfile/getCredentials",
      }),
    },
    created() {
      this.loadInitialProfile();
      this.userIdentifier = this.storeUserIdentifier;
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if(code){
        this.handleOrcidCallback();
      } else{
        this.checkLoggedIn();
        this.checkOrcidStatus();
      }
    },
    methods: {
      ...mapGetters({
        getCredentials: "authProfile/getCredentials",
      }),
      ...mapActions({
        fetchOrcidLoginUrl: "authProfile/getOrcidLoginUrl",
        loadAuthProfile: "authProfile/loadAuthProfile",
        invalidateAuth: "authProfile/invalidate",
      }),
      async loadInitialProfile(){
        await this.loadAuthProfile();
      },
      async checkLoggedIn() {
        this.isUserLoggedIn = !!this.userIdentifier;
      },
      async checkOrcidStatus() {
        if (!this.userIdentifier) {
          console.error('User identifier is not available');
          this.isLoading = false;
          return;
        }
        try {
          const response = await checkOrcidStatus(this.userIdentifier, this.partnerId);
          this.isOrcidLinked = response.has_orcid;
          if (this.isOrcidLinked == true) {
            this.proceedToTAIR();
          }
        } catch (error) {
          console.error('Error checking ORCID status:', error);
          this.errorMessage = 'Unable to check ORCID status. Please try again later.';
        } finally {
          this.isLoading = false;
        }
      },
      async connectOrcid() {
        this.isConnecting = true;
        this.errorMessage = '';
        try {
          const orcidLoginUrl = await this.fetchOrcidLoginUrl();
          if (orcidLoginUrl) {
            window.location.href = orcidLoginUrl; // Redirect to ORCID login page in the same tab
          } else {
            throw new Error('ORCID login URL is empty');
          }
        } catch (error) {
          console.error('Error fetching ORCID login URL:', error);
          this.errorMessage = 'Unable to connect to ORCID. Please try again later.';
          this.isConnecting = false;
        }
      },
      proceedToTAIR() {
        this.$router.push('/profile');
      },
      register() {
        window.location.href = '/register';
      },
      async handleOrcidCallback() {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
          this.isLoading = true;
          this.errorMessage = '';

          try {
            let response;
            if (code && this.storeUserIdentifier) {
              // User is already logged in, just linking ORCID
              const credentialId = Cookies.get("credentialId");
              const secretKey = Cookies.get("secretKey");
              console.log(this.orcidAlreadyLinked);
              response = await processOrcidCode(code, this.storeUserIdentifier, credentialId, secretKey, this.partnerId);
              console.log(response);
              if (!response.success) {
                this.orcidAlreadyLinked = true;
              }
              console.log(this.orcidAlreadyLinked);
            } else {
              // User is not logged in, perform ORCID authentication
              response = await authenticateOrcid(code);
            }

            if (!response) {
              throw new Error('No response received from authentication service');
            }

            

            if (response.success) {
              if (this.storeUserIdentifier) {
                // Linking ORCID to existing account
                this.isUserLoggedIn = true;
                this.$store.commit('authProfile/UPDATE_STATE', { orcidId: response.orcidId });
                // this.$toast.success(response.message || 'ORCID account successfully linked.');
              } else {
                // New user authentication
                this.isUserLoggedIn = true;
                Cookies.set('credentialId', response.user.credentialId, { expires: 7 });
                Cookies.set('secretKey', response.user.secretKey, { expires: 7 });
                // Set cookies to .arabidopsis.org so other applications also work
                Cookies.set('credentialId', response.user.credentialId, {
                  expires: 7,            // 7 days
                  domain: '.arabidopsis.org',
                  path: '/'
                });
                Cookies.set('secretKey', response.user.secretKey, {
                  expires: 7,
                  domain: '.arabidopsis.org',
                  path: '/'
                });
                // Cookies.set('credentialId', response.user.userIdentifier, { expires: 7 });
                // Cookies.set('orcidId', response.orcidId, { expires: 7 });
                // this.$toast.success('Successfully logged in with ORCID.');
              }

              await this.loadAuthProfile();
              this.isOrcidLinked = true;

              this.isLoading = false;
              setTimeout(() => {
                this.$router.push('/profile');
              }, 1000);
            } else {
              throw new Error(response.message || 'Authentication failed');
            }
          } catch (error) {
            console.error('Error processing ORCID code:', error);
            this.errorMessage = error.message || 'Unable to complete ORCID connection. Please try again later.';
            this.$toast.error(this.errorMessage);
            await this.invalidateAuth();
          } finally {
            this.isLoading = false;
          }
        } else {
          console.log("No ORCID code found in URL");
        }
      },
    }
  }
  </script>