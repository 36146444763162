<template>
    <div class="login-container d-flex align-items-center justify-content-center bg-white border-black">
        <div class="login-content text-center">
        <h1 class="mb-4">TAIR Login Page</h1>
        <!-- <h4 class="text-muted mb-4">Please choose your preffered login method:</h4> -->
        <br>
        <div class="d-grid gap-3">
            <button @click="loginInternal" class="btn btn-primary text-white btn-lg w-100 mb-2">
            Login with Credentials
            </button>
            <button @click="loginOrcid" class="btn btn-outline-secondary btn-lg w-100 d-flex align-items-center justify-content-center">
            <img src="/orcid.png" alt="ORCID logo" class="me-2" style="height: 36px; width: auto;" />
            <span>Login with ORCID</span>
            </button>
            <br>
            <p class="text-danger small mb-2">
            Note: ORCID login only works if your TAIR account is already linked with ORCID.
            </p>
            <button @click="register" class="btn btn-secondary text-white btn-lg w-100 mb-2">
            New to TAIR? Register Here
            </button>
        </div>
        </div>
    </div>
</template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    name: 'LoginPage',
    methods: {
      ...mapGetters("authProfile", ["getLoginUrl"]),
      ...mapActions({
        fetchOrcidLoginUrl: "authProfile/getOrcidLoginUrl",
      }),
      async loginInternal() {
        const regularLoginURL = this.getLoginUrl();
        console.log(regularLoginURL);
        console.log('Internal login clicked');
        window.location.href = regularLoginURL;
      },
      register() {
        console.log('Register clicked');
        window.location.href = '/register';
      },
      async loginOrcid() {
        console.log('ORCID login clicked');
        const orcidLoginUrl = await this.fetchOrcidLoginUrl();
        console.log(orcidLoginUrl);
        if (orcidLoginUrl) {
          window.location.href = orcidLoginUrl;
        } else {
          console.error('ORCID login URL not available');
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .login-container {
    min-height: 60vh;
    background-color: #f8f9fa;
  }
  .login-content {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }
  h1 {
    color: #333;
    font-weight: 600;
  }
  .btn {
    padding: 0.75rem 1rem;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  .btn-primary {
    background-color: #4285f4;
    border-color: #4285f4;
  }
  .btn-primary:hover, .btn-primary:focus {
    background-color: #3367d6;
    border-color: #3367d6;
    box-shadow: 0 0 0 0.2rem rgba(66, 133, 244, 0.5);
  }
  .btn-outline-secondary {
    color: #5f6368;
    border-color: #dadce0;
  }
  .btn-outline-secondary:hover, .btn-outline-secondary:focus {
    background-color: #f8f9fa;
    color: #202124;
    box-shadow: 0 0 0 0.2rem rgba(95, 99, 104, 0.2);
  }
  </style>