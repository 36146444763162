<template>
  <div>
    <pre class="fasta-sequence">{{ fastaString }}</pre>
  </div>
</template>

<script>
import PostsService from "@/services/PostsService";

export default {
  data() {
    return {
      fastaString: "Loading ...",
    };
  },
  async created() {
    await this.fetchFastaString();
  },
  methods: {
    async fetchFastaString() {
      try {
        let key = this.$route.query.key;
        let search_params = {};
        search_params = JSON.parse(localStorage.getItem(key));
        search_params.key = key;
        const response = await PostsService.fetchSequences({ search_params });

        if (response.data.error) {
          console.error("Error fetching sequences:", response.data.error);
          this.fastaString = response.data.error;
        } else {
          this.fastaString = response.data;
        }
      } catch (error) {
        console.error("Error fetching sequences:", error);
        this.fastaString = error;
      }
    },
  },
};
</script>
<style scoped>
.fasta-sequence {
  text-align: left;
  white-space: pre-wrap; /* Wrap text to prevent overflow */
  word-wrap: break-word; /* Break words to prevent overflow */
  max-width: 100%; /* Prevent the pre element from extending beyond the page width */
}
</style>
