import store from "@/store/store";

export const authRoutes = {
  path: "/auth",
  children: [
    {
      path: "login",
      name: "Login",
      beforeEnter: async (to, from, next) => {
        // execute the store fetch
        const loaded = await store.dispatch("authProfile/loadAuthProfile");
        if (loaded && store.getters["authProfile/isLoggedIn"]) {
          // If the user is already logged in, redirect to home page
          // and force refresh
          next({ path: "/linktoorcid" });
          window.location.reload();
        } else {
          // Redirect to home page and force refresh
          next({ path: "/" });
          window.location.reload();
        }
      },
    },
    {
      path: "logout",
      name: "Logout",
      beforeEnter: async (to, from, next) => {
        await store.dispatch("authProfile/invalidate");
        next({ path: "/" });
        window.location.reload();
      },
    },
  ],
};