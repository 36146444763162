<template>
  <div class="searchWrapper d-flex flex-column">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="font-weight-bold text-left title ml-2">
          {{ searchResultsHeader }}
        </div>
        <TopMenu
          :category="category"
          @download-csv="handleDownloadCsv"
          @new-search="handleNewSearch"
          @go-search="handleGoSearch"
          @po-search="handlePoSearch"
          @get-descriptions="handleGeneDescriptions"
          @get-locus-history="handleLocusHistory"
          @get-sequences="handleGetSequences"
          @get-microarray="handleMicroarray"
        />
        <div v-if="errorMsg" class="alert alert-danger" role="alert">
          {{ errorMsg }}
        </div>
      </div>
      <div>
        <div class="pageDesc mt-2">
          <div class="d-flex flex-column">
            <span
              class="text-left px-4 statement"
              v-html="queryStatement"
            ></span>
            <span
              class="text-left px-4 statement2"
              v-html="filteredStatement"
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 mt-3 mb-2 ml-2">
        <div v-if="showGeneListData && filteredOutGenes.length > 0" class="gene-list-dropdown">
          <b-button v-b-toggle.accordion-gene-list variant="custom" class="d-flex justify-content-between align-items-center w-100 custom-dropdown-button">
            <span>Filtered Out Genes/Loci</span>
            <div>
              <b-badge variant="light" class="mr-2">{{ filteredOutGenes.length }}</b-badge>
              <b-icon :icon="visible ? 'chevron-up' : 'chevron-down'"></b-icon>
            </div>
          </b-button>
          
          <b-collapse id="accordion-gene-list" v-model="visible" class="mt-2">
            <b-card no-body class="custom-dropdown-card">
              <b-list-group flush>
                <b-list-group-item v-for="geneId in filteredOutGenes" :key="geneId" class="py-2">
                  {{ geneId }}
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-collapse>
        </div>
      </div>
    </div>

    <div class="pageContent">
      <div class="d-flex align-content-left m-2">
        Displaying {{ localCurrentPage * perPage - perPage + 1 }} -
        {{
          items.length < localCurrentPage * perPage
            ? items.length
            : localCurrentPage * perPage
        }}
        of
        <span v-if="items.length == 10000">&nbsp;first&nbsp;</span>
        {{ items.length }} results
      </div>

      <ResultsTable
        :items="items"
        :fields="fields"
        :loading="loading"
        :error="error"
        @cell-clicked="handleCellClick"
        @update:currentPage="localCurrentPage = $event"
        @row-selected="handleRowSelected"
      />
    </div>
    <sequence-modal
      ref="sequenceModal"
      :gene-list="geneList"
      :geneListType="geneListType"
      @submit="handleSequenceModalSubmit"
    ></sequence-modal>
    <microarray-modal
      ref="microarrayModal"
      @submit="handleMicroarrayModalSubmit"
    ></microarray-modal>
  </div>
</template>

<script>
import axios from "axios";
import CSVDownloadManager from "@/services/CSVDownloadManager";
import * as geneSearch from "@/services/search/geneSearch";
import * as dnaSearch from "@/services/search/dnaSearch";
import * as ecotypeSearch from "@/services/search/ecotypeSearch";
import * as annotationsSearch from "@/services/search/annotationsSearch";
import * as pubSearch from "@/services/search/pubSearch";
import * as communitySearch from "@/services/search/communitySearch";
import * as germplasmSearch from "@/services/search/germplasmSearch";
import * as keywordSearch from "@/services/search/keywordSearch";
import * as markerSearch from "@/services/search/markerSearch";
import * as polySearch from "@/services/search/polySearch";
import * as protocolSearch from "@/services/search/protocolSearch";
import * as proteinSearch from "@/services/search/proteinSearch";
import * as transposonSearch from "@/services/search/transposonSearch";
import { BPagination } from "bootstrap-vue";
import TopMenu from "@/components/search/TopMenu.vue";
import ResultsTable from "@/components/search/ResultsTable.vue";
import SequenceModal from "@/components/SequenceModal.vue";
import MicroarrayModal from "@/components/MicroarrayModal.vue";

export default {
  name: "SearchResults",
  props: {
    initialShowGeneListData: Boolean,
    initialGeneList: Array
  },
  data() {
    return {
      category: "gene",
      searchResultsHeader: "TAIR Search Results",
      searchTerm: "",
      lociMatchCount: 0,
      geneModelCount: 0,
      selected: [],
      items: [],
      fields: [],
      loading: false,
      error: false,
      queryStatement: "",
      filteredStatement: "",
      localCurrentPage: 1,
      perPage: 25,
      pages: 0,
      errorMsg: "",
      geneListData: [],
      showGeneListData: this.initialShowGeneListData,
      geneList: this.initialGeneList || [],
      geneListType: "",
      visible: false,
      matchedGenes: [],
      filteredOutGenes: [], // New property to store filtered out genes
    };
  },
  props: {
    showGeneListData: Boolean,
    geneList: Array
  },
  watch: {
    "$route.query": function (id) {
      this.init();
    },
    geneList: {
      handler: 'updateFilteredOutGenes',
      deep: true
    },
    matchedGenes: {
      handler: 'updateFilteredOutGenes',
      deep: true
    },
  },
  components: {
    BPagination,
    TopMenu,
    ResultsTable,
    SequenceModal,
    MicroarrayModal,
  },
  mounted() {
    this.showGeneListData = this.initialShowGeneListData;
    this.geneList = this.initialGeneList || [];
    this.init();
  },
  methods: {
    init() {
      this.category = this.$route.query.category;
      if (this.$route.query) {
        this.queryStatement = "Searching ...";
        if (this.$route.query.mainType == "general") {
          this.retrieveGeneralResults(this.$route.query);
        } else if (this.$route.query.mainType == "detail") {
          this.retrieveDetailResults(this.$route.query);
        }
      }
    },
    processGeneListData() {
      if (this.geneListData) {
        this.geneList = this.geneListData.split(";");
        this.updateFilteredOutGenes(); // Update filteredOutGenes when geneList changes
      }
    },
    updateFilteredOutGenes() {
      if (Array.isArray(this.geneList) && Array.isArray(this.matchedGenes)) {
        this.filteredOutGenes = this.geneList.filter(gene => !this.matchedGenes.includes(gene));
      } else {
        this.filteredOutGenes = [];
      }
    },
    //Retrieve Results
    async retrieveGeneralResults(queryParams) {
      this.error = false;
      this.loading = true;
      this.items = [];
      try {
        let search_params = {};
        queryParams.searchText = queryParams.searchText.trim();
        switch (queryParams.category) {
          case "genes":
            this.showGeneListData = false;
            this.geneListData = [];
            this.geneList = [];
            search_params = {
              searchText: queryParams.searchText,
            };
            this.loading = true; // Ensure this variable is defined in your component's state.
            document.title = "Loading Search Results - Genes"; // Indicate loading in title.
            try {
              let result = await geneSearch.loadResults(
                search_params,
                "general"
              );
              Object.assign(this, result);
            } catch (error) {
              console.error("Failed to load gene results:", error);
              this.error = true; // Handle any errors that occur during fetching.
            } finally {
              this.loading = false;
              document.title = "Search Results - Genes"; // Reset title after loading completes.
            }
            break;
          case "dna":
            search_params = {
              category: "clone",
              searchText: queryParams.searchText,
            };
            Object.assign(
              this,
              await dnaSearch.loadResults(search_params, "general")
            );
            this.loading = false;
            document.title = "Search Results - Clone";
            break;
          case "vector":
            search_params = {
              category: "vector",
              searchText: queryParams.searchText,
            };
            Object.assign(
              this,
              await dnaSearch.loadResults(search_params, "general")
            );
            this.loading = false;
            document.title = "Search Results - Vector";
            break;
          case "keyword":
            search_params = {
              searchText: queryParams.searchText,
            };
            Object.assign(
              this,
              await keywordSearch.loadResults(search_params, "general")
            );
            this.loading = false;
            document.title = "Search Results - Keywords";
            break;
          case "protein":
            search_params = {
              searchText: queryParams.searchText,
            };
            Object.assign(
              this,
              await proteinSearch.loadResults(search_params, "general")
            );
            this.loading = false;
            document.title = "Search Results - Proteins";
            break;
          case "polyallele":
            search_params = {
              searchText: queryParams.searchText,
            };
            Object.assign(
              this,
              await polySearch.loadResults(search_params, "general")
            );
            this.loading = false;
            document.title = "Search Results - Polymorphisms/Alleles";
            break;
          case "ecotype":
            search_params = {
              searchText: queryParams.searchText,
            };
            Object.assign(
              this,
              await ecotypeSearch.loadResults(search_params, "general")
            );
            this.loading = false;
            document.title = "Search Results - Ecotypes";
            break;
          case "community":
            search_params = {
              searchText: queryParams.searchText,
            };
            Object.assign(
              this,
              await communitySearch.loadResults(search_params, "general")
            );
            this.loading = false;
            document.title = "Search Results - Community";
            break;
          case "germplasm":
            search_params = {
              searchText: queryParams.searchText,
            };
            Object.assign(
              this,
              await germplasmSearch.loadResults(search_params, "general")
            );
            this.loading = false;
            document.title = "Search Results - Germplasms";
            break;
          case "markers":
            search_params = {
              searchText: queryParams.searchText,
            };
            Object.assign(
              this,
              await markerSearch.loadResults(search_params, "general")
            );
            this.loading = false;
            document.title = "Search Results - Markers";
            break;
          case "transposon":
            search_params = {
              searchText: queryParams.searchText,
            };
            Object.assign(
              this,
              await transposonSearch.loadResults(search_params, "general")
            );
            this.loading = false;
            document.title = "Search Results - Transposons";
            break;
          case "exact_name_search":
            search_params = {
              searchText: queryParams.searchText,
            };
            Object.assign(
              this,
              await geneSearch.loadResults(search_params, "general")
            );
            this.loading = false;
            document.title = "Search Results - Exact Name Search";
            break;
          case "Metabolic Pathways":
            break;

          default:
            this.error = true;
            this.loading = false;
            break;
        }
      } catch (error) {
        console.log(
          error.response ? error.response.statusText : "Unknwon error"
        );
        this.loading = false;
        this.error = true;
        return;
      }
    },
    async retrieveDetailResults(queryParams) {
      this.error = false;
      this.loading = true;
      this.items = [];

      //   let search_params = JSON.parse(queryParams.search_params);
      let search_params = {};
      try {
        let key = queryParams.key;
        if (key == undefined || key == null || key == "") {
          search_params = JSON.parse(queryParams.search_params);
        } else {
          search_params = JSON.parse(localStorage.getItem(key));
          search_params.key = key;
        }

        switch (queryParams.category) {
          case "dna":
            Object.assign(this, await dnaSearch.loadResults(search_params));
            this.loading = false;
            document.title = "Search Results - DNA";
            break;
          case "annotations":
            Object.assign(
              this,
              await annotationsSearch.loadAnnotationsResults(search_params)
            );
            this.loading = false;
            break;
          case "genes":
            this.showGeneListData = true;
            this.geneListType = search_params.geneListUploadType;
            this.loading = true; // Ensure this variable is defined in your component's state.
            document.title = "Loading Search Results - Genes"; // Indicate loading in title.
            try {
                let result = await geneSearch.loadResults(search_params);
                Object.assign(this, result);
                if (result && result.geneListData) {
                    this.geneListData = result.geneListData; // Make sure geneListData is expected part of result.
                    this.processGeneListData();
                    // Update matchedGenes based on the search results
                    // this.matchedGenes = result.items.flatMap(item => 
                    //   item.originalDoc.gene_model_ids.filter(id => this.geneList.includes(id))
                    // );
                    // this.matchedGenes = result.items.flatMap(item => 
                    //   item.originalDoc.gene_name.filter(id => this.geneList.includes(id))
                    // );
                    this.matchedGenes = [...new Set(result.items.flatMap(item => {
                      const gene_model_ids = Array.isArray(item.originalDoc.gene_model_ids) ? item.originalDoc.gene_model_ids : [];
                      const gene_name = Array.isArray(item.originalDoc.gene_name) ? item.originalDoc.gene_name : [];
                      
                      const matchedModelIds = gene_model_ids.filter(id => this.geneList.includes(id));
                      const matchedGeneNames = gene_name.filter(name => this.geneList.includes(name));
                      
                      return [...matchedModelIds, ...matchedGeneNames];
                    }))];
                    this.updateFilteredOutGenes();
                }
            } catch (error) {
              console.error("Failed to load gene results:", error);
              this.error = true; // Handle any errors that occur during fetching.
            } finally {
              this.loading = false;
              document.title = "Search Results - Genes"; // Reset title after loading completes.
            }
            break;
          case "descriptions":
            Object.assign(
              this,
              await geneSearch.loadDescriptions(search_params)
            );
            this.loading = false;
            document.title = "Search Results - Gene Descriptions";
            break;
          case "locus_history":
            Object.assign(
              this,
              await geneSearch.loadLocusHistory(search_params)
            );
            this.loading = false;
            document.title = "Search Results - Locus History";
            break;
          case "microarray":
            Object.assign(this, await geneSearch.loadMicroarray(search_params));
            this.loading = false;
            document.title = "Search Results - Microarray Elements";
            break;
          case "protein":
            Object.assign(this, await proteinSearch.loadResults(search_params));
            this.loading = false;
            document.title = "Search Results - Proteins";
            break;
          case "germplasm":
            Object.assign(
              this,
              await germplasmSearch.loadResults(search_params)
            );
            this.loading = false;
            document.title = "Search Results - Germplasms";
            break;
          case "keyword":
            Object.assign(this, await keywordSearch.loadResults(search_params));
            this.loading = false;
            document.title = "Search Results - Keywords";
            break;
          case "polyallele":
            Object.assign(this, await polySearch.loadResults(search_params));
            this.loading = false;
            document.title = "Search Results - Polymorphisms/Alleles";
            break;
          case "publication":
            Object.assign(this, await pubSearch.loadPubResults(search_params));
            this.loading = false;
            document.title = "Search Results - Publications";
            break;
          case "community":
            Object.assign(
              this,
              await communitySearch.loadResults(search_params)
            );
            this.loading = false;
            document.title = "Search Results - Community";
            break;
          case "markers":
            Object.assign(this, await markerSearch.loadResults(search_params));
            this.loading = false;
            document.title = "Search Results - Markers";
            break;
          case "ecotype":
            Object.assign(this, await ecotypeSearch.loadResults(search_params));
            this.loading = false;
            document.title = "Search Results - Ecotypes";
            break;
          case "transposon":
            Object.assign(
              this,
              await transposonSearch.loadResults(search_params)
            );
            this.loading = false;
            document.title = "Search Results - Transposons";
            break;
          case "protocol":
            Object.assign(
              this,
              await protocolSearch.loadResults(search_params)
            );
            this.loading = false;
            document.title = "Search Results - Protocols";
            break;
          default:
            this.searchTerm = search_params.searchText;
        }
      } catch (error) {
        console.log(
          error.response ? error.response.statusText : "Unknwon error"
        );
        this.loading = false;
        this.error = true;
        return;
      }
    },

    //Events
    handleCellClick({ cellType, value }) {
      if (cellType === "locus") {
        const route = this.$router.resolve({
          path: "/locus",
          query: { key: value },
        });
        // window.open(route.href, "_self");
        this.$router.push(`/locus?key=${value}`);
      } else if (cellType === "gene") {
        const route = this.$router.resolve({
          path: "/gene",
          query: { key: value },
        });
        this.$router.push(`/gene?key=${value}`);
      } else if (cellType === "protein") {
        const route = this.$router.resolve({
          path: "/protein",
          query: { key: value },
        });
        this.$router.push(`/protein?key=${value}`);
      } else if (cellType === "germplasm") {
        const route = this.$router.resolve({
          path: "/germplasm",
          query: { key: value },
        });
        this.$router.push(`/germplasm?key=${value}`);
      }
    },
    handleRowSelected(items) {
      this.errorMsg = "";
    },
    handleNewSearch() {
      let category = this.$route.query.category;
      let geneCats = [
        "go_annotations",
        "po_annotations",
        "annotations",
        "genes",
        "descriptions",
        "locus_history",
        "microarray",
      ];
      if (geneCats.includes(this.$route.query.category)) {
        category = "genes";
      }
      this.$router.push(`/search/${category}`);
    },
    handleGetSequences() {
      let selectedItems = this.items.filter((item) => item.selected);
      if (selectedItems.length > 0) {
        this.$refs.sequenceModal.show();
      } else {
        this.errorMsg = "No genes selected";
      }
    },
    handleSequenceModalSubmit({ option, format }) {
      let selectedItems = this.items.filter((item) => item.selected);
      let genes = null;
      let isLocus = !this.geneList.some(gene => gene.includes('.'));

      if (selectedItems.length > 0) {
        if (isLocus) {
          genes = selectedItems
            .map((item) => item.locus.name)
            .filter(id => this.geneList.includes(id))
            .join("\t");
        } else {
          genes = selectedItems
            .flatMap((item) => item.originalDoc.gene_model_ids)
            .filter(id => this.geneList.includes(id))
            .join("\t");
        }

        // TAIR3-506 --> handles case when geneList is technically empty since the main search bar was used instead of advanced search
        if(this.geneList.length === 0){
          genes = selectedItems.map((item) => item.locus.name).join("\t");
        }

        let searchParams = {
          selectedOption: option,
          selectedFormat: format,
          searchForString: genes,
          locus: isLocus,
        };
        let key = "searchParams_" + Date.now();
        localStorage.setItem(key, JSON.stringify(searchParams));
        const link = `/sequences?key=${key}`;
        window.open(link, "_blank");
        this.$refs.sequenceModal.hide();
      } else {
        this.errorMsg = "No genes selected";
      }
    },
    handleGeneDescriptions() {
      let selectedItems = this.items.filter((item) => item.selected);
      if (selectedItems.length > 0) {
        let genes = selectedItems.map((item) => item.locus.name).join("\t");
        let searchParams = {
          type: "locus",
          filename: "locus",
          searchForString: genes,
        };
        let key = "searchParams_" + Date.now();
        localStorage.setItem(key, JSON.stringify(searchParams));
        const link = `/results?mainType=detail&category=descriptions&key=${key}`;
        window.open(link, "_blank");
      } else {
        this.errorMsg = "No genes selected";
      }
    },
    handleLocusHistory() {
      let selectedItems = this.items.filter((item) => item.selected);
      if (selectedItems.length > 0) {
        let genes = selectedItems.map((item) => item.locus.name).join("\t");
        let searchParams = {
          searchForString: genes,
        };
        let key = "searchParams_" + Date.now();
        localStorage.setItem(key, JSON.stringify(searchParams));
        const link = `/results?mainType=detail&category=locus_history&key=${key}`;
        window.open(link, "_blank");
      } else {
        this.errorMsg = "No genes selected";
      }
    },
    handleMicroarray() {
      let selectedItems = this.items.filter((item) => item.selected);
      if (selectedItems.length > 0) {
        let genes = selectedItems.map((item) => item.locus.name).join("\t");
        this.$refs.microarrayModal.show();
      } else {
        this.errorMsg = "No genes selected";
      }
    },
    handleMicroarrayModalSubmit({ format }) {
      let selectedItems = this.items.filter((item) => item.selected);
      if (selectedItems.length > 0) {
        let genes = selectedItems.map((item) => item.locus.name).join("\t");
        let searchParams = {
          searchFrom: format, //"AFFY_ATH1",
          searchForString: genes,
        };
        let key = "searchParams_" + Date.now();
        localStorage.setItem(key, JSON.stringify(searchParams));
        const link = `/results?mainType=detail&category=microarray&key=${key}`;
        window.open(link, "_blank");
        this.$refs.microarrayModal.hide();
      } else {
        this.errorMsg = "No genes selected";
      }
    },
    handleGoSearch() {
      let selectedItems = this.items.filter((item) => item.selected);
      if (selectedItems.length > 0) {
        let genes = selectedItems.map((item) => item.locus.name).join("\t");
        let searchParams = {
          locus_list: genes,
          type: "go",
          sortBy: "locus_name",
        };
        let key = "searchParams_" + Date.now();
        localStorage.setItem(key, JSON.stringify(searchParams));
        const link = `/results?mainType=detail&category=annotations&key=${key}`;
        window.open(link, "_blank");
      } else {
        this.errorMsg = "No genes selected";
      }
    },
    handlePoSearch() {
      let selectedItems = this.items.filter((item) => item.selected);
      if (selectedItems.length > 0) {
        let genes = selectedItems.map((item) => item.locus.name).join("\t");
        let searchParams = {
          locus_list: genes,
          type: "po",
          sortBy: "locus_name",
        };
        let key = "searchParams_" + Date.now();
        localStorage.setItem(key, JSON.stringify(searchParams));
        const link = `/results?mainType=detail&category=annotations&key=${key}`;
        window.open(link, "_blank");
      } else {
        this.errorMsg = "No genes selected";
      }
    },
    async getJsonData(locusId) {
      let base_url = "https://tair-data.s3-us-west-2.amazonaws.com/prod/locus/";
      let url = base_url + locusId + ".json";
      return axios({
        method: "get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
        url: url,
      });
    },

    //Download Results
    handleDownloadCsv({ category, type }) {
      CSVDownloadManager.items = this.items;
      CSVDownloadManager.query = this.$route.query;
      CSVDownloadManager.downloadCsv(category, type);
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
}
.statement {
  font-size: 20px;
}
.statement2 {
  font-size: 16px;
}
.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.custom-gap {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.keySearchColor {
  color: #ff0000;
}

.ROWheader {
  align-items: center;
  justify-content: center;
  vertical-align: text-top;
}
.tight-checkbox {
  padding: 0px;
  margin: 0px;
}
.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hashtag-icon {
  font-size: 16px;
  padding-bottom: 7px;
  justify-content: center;
  padding-left: 2px;
}
.center-content {
  display: flex;
  align-items: center; // Vertically center aligned
  justify-content: center; // Horizontally center aligned
}
.gene-list-dropdown {
  width: 100%;
}

.list-group {
  max-height: 200px;
  overflow-y: auto;
}

.custom-dropdown-button {
  background-color: #C3CE7D !important;
  border-color: #B3BE6D !important;
  color: #000000 !important;
}

.custom-dropdown-button:hover,
.custom-dropdown-button:focus,
.custom-dropdown-button:active {
  background-color: #B3BE6D !important;
  border-color: #A3AE5D !important;
  color: #000000 !important;
}

.custom-dropdown-card {
  border-color: #C3CE7D !important;
}

.custom-dropdown-button .badge {
  background-color: #FFFFFF;
  color: #000000;
}
</style>
