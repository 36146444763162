// orcidService.js
import Api from "@/services/Api";

export async function checkOrcidStatus(userIdentifier, partnerId) {
  try {
    const response = await Api().get("auth/orcidstatus", {
      params: {
        userIdentifier,
        partnerId,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error checking ORCID status:', error);
    return null;
  }
}

//get userIdentifier from orcidId
export async function getUserIdentifier(code) {
  try {
    const response = await Api().get("auth/getUserIdentifier", {
      params: {
        code,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error getting ORCID ID:', error);
    return null;
  }
}

export async function processOrcidCode(code, userIdentifier, credentialId, secretKey, partnerId) {
    try {
      const response = await Api().post("auth/process-orcid-code", {
        code,
        userIdentifier,
        credentialId, 
        secretKey,
        partnerId,
      });
  
      return { success: true, ...response.data };
    } catch (error) {
      console.error('Error processing ORCID code:', error);
      throw error;
    }
  }

export async function authenticateOrcid(code) {
  try {
    const response = await Api().post("auth/authenticate-orcid", {
      code,
    });

    return { success: true, ...response.data };
  } catch (error) {
    console.error('Error authenticating ORCID:', error);
    throw error;
  }
}

export async function unlinkOrcid(credentialId, secretKey) {
  try {
    const response = await Api().post("auth/unlink-orcid", {
      credentialId,
      secretKey,
    });
    return response.data;
  } catch (error) {
    console.error('Error unlinking ORCID:', error);
    throw error;
  }
}