import Api from "@/services/Api";

export default {
  fetchPosts() {
    return Api().get("testJava");
  },
  fetchGeneralSearch(params) {
    return Api().post("search/general", params);
  },
  fetchDnaDetailSearch(params) {
    return Api().post("search/dna", params);
  },
  fetchGeneDetailSearch(params) {
    return Api().post("search/gene", params);
  },
  getLocusIdByName(query) {
    return Api().get("detail/locus/name", { params: query });
  },
  getDetailsByAccession(query) {
    return Api().get("detail/locus/accession", { params: query });
  },
  getGeneIdByName(query) {
    return Api().get("detail/gene/name", { params: query });
  },
  getCloneIdByName(query) {
    return Api().get("detail/clone/name", { params: query });
  },
  getCloneEndIdByAccession(query) {
    return Api().get("detail/cloneend/accession", { params: query });
  },
  getPolymorphisIdByName(query) {
    return Api().get("detail/polymorphism/name", { params: query });
  },
  getPolymorphisIdByAccession(query) {
    return Api().get("detail/polymorphism/accession", { params: query });
  },
  getMarkerIdByAccession(query) {
    return Api().get("detail/marker/accession", { params: query });
  },
  fetchGeneDescriptionSearch(params) {
    return Api().post("search/gene/descriptions", params, {params: {key: params.key}});
  },
  fetchLocusHistory(params) {
    return Api().post("search/gene/locus_history", params, {params: {key: params.key}});
  },
  fetchGeneMicroarraySearch(params) {
    return Api().post("search/gene/microarray", params, {params: {key: params.key}});
  },
  fetchProteinDetailSearch(params) {
    return Api().post("search/protein", params);
  },
  fetchGermplasmDetailSearch(params) {
    return Api().post("search/germplasm", params);
  },
  fetchKeywordDetailSearch(params) {
    return Api().post("search/keyword", params);
  },
  fetchAnnotationsDetailSearch(params, paid=true) {
    if(paid) {
      return Api().post("search/annotation", params, {params: {key: params.key}});
    } else {
      return Api().post("search/free/annotation", params, {params: {key: params.key}});
    }
  },
  fetchPolyDetailSearch(params) {
    return Api().post("search/polymorphism", params);
  },
  fetchPublicationSearch(params) {
    return Api().post("search/publication", params);
  },
  fetchCommunitySearch(params) {
    return Api().post("search/community", params);
  },
  updateCommunity(params) {
    return Api().post("update/community", params);
  },
  fetchMarkerSearch(params) {
    return Api().post("search/marker", params);
  },
  fetchEcotypeSearch(params) {
    return Api().post("search/ecotype", params);
  },
  fetchTransposonSearch(params) {
    return Api().post("search/transposon", params);
  },
  fetchTransposonFamiliesSearch(params) {
    return Api().post("search/transposonfamily", params);
  },
  fetchGeneSymbolSearch(params) {
    return Api().post("search/genesymbol", params);
  },
  fetchProtocolSearch(params) {
    return Api().post("search/protocol", params);
  },
  fetchSequences(params) {
    return Api().post("search/sequences", params, {params: {key: params.search_params.key}});
  },
  getLocusDetail(query) {
    return Api().get("detail/locus", { params: query });
  },
  getGeneDetail(query) {
    return Api().get("detail/gene", { params: query });
  },
  getGeneFamilyDetail(query) {
    return Api().get("detail/genefamily", { params: query });
  },
  getMicroarrayExperimentDetail(query) {
    return Api().get("detail/microarrayexperiment", { params: query });
  },
  getGeneSymbolDetail(query) {
    return Api().get("detail/genesymbol", { params: query });
  },
  getCloneDetail(query) {
    return Api().get("detail/clone", { params: query });
  },
  getCloneEndDetail(query) {
    return Api().get("detail/cloneend", { params: query });
  },
  getVectorDetail(query) {
    return Api().get("detail/vector", { params: query });
  },
  getEcotypeDetail(query) {
    return Api().get("detail/ecotype", { params: query });
  },
  getHostStrainDetail(query) {
    return Api().get("detail/hoststrain", { params: query });
  },
  getLibraryDetail(query) {
    return Api().get("detail/library", { params: query });
  },
  getArrayElementDetail(query) {
    return Api().get("detail/arrayelement", { params: query });
  },
  getArrayElementById(query) {
    return Api().get("detail/arrayelement/name", { params: query });
  },
  getArrayElementByAccession(query) {
    return Api().get("detail/arrayelement/accession", { params: query });
  },
  getGermplasmDetail(query) {
    return Api().get("detail/germplasm", { params: query });
  },
  getKeywordDetail(query) {
    return Api().get("detail/keyword", { params: query });
  },
  getMarkerDetail(query) {
    return Api().get("detail/marker", { params: query });
  },
  getSequenceDetail(query) {
    return Api().get("detail/sequence", { params: query });
  },
  getPolymorphismDetail(query) {
    return Api().get("detail/polymorphism", { params: query });
  },
  getProteinDetail(query) {
    return Api().get("detail/protein", { params: query });
  },
  getPublicationDetail(query) {
    return Api().get("detail/publication", { params: query });
  },
  getTransposonDetail(query) {
    return Api().get("detail/transposon", { params: query });
  },
  getTransposonFamilyDetail(query) {
    return Api().get("detail/transposonfamily", { params: query });
  },
  getProtocolDetail(query) {
    return Api().get("detail/protocol", { params: query });
  },
  getProtocolIdById(query) {
    return Api().get("detail/protocol/id", { params: query });
  },
  getPersonDetail(query) {
    return Api().get("detail/person", { params: query });
  },
  getOrganizationDetail(query) {
    return Api().get("detail/organization", { params: query });
  },
  getAnalysisReferenceDetail(query) {
    return Api().get("detail/analysisreference", { params: query });
  },
  getCommunicationDetail(query) {
    return Api().get("detail/communication", { params: query });
  },
};
