<template>
  <!-- <div class="detail-wrapper"> -->
  <div class="registration-container">
    <h3>User Registration Form</h3>
    <div v-if="json_data" class="registration-form">
      <form @submit.prevent="registerPerson" class="form-container">
        <div class="input-container">
          <!-- First Name -->
          <div class="input-group">
            <label for="username">Username</label>
            <input
              type="text"
              id="username"
              v-model="json_data.username"
              class="form-control"
              required
            />
          </div>

          <div class="input-group">
            <label for="password">Password</label>
            <input
              type="password"
              id="password"
              v-model="json_data.password"
              class="form-control"
              placeholder="Password must be between 6 and 10 characters."
              required
            />
            <span class="error-message" v-if="passwordError">{{
              passwordError
            }}</span>
          </div>

          <div class="input-group">
            <label for="password">Password Confirm</label>
            <input
              type="password"
              id="password_confirm"
              v-model="json_data.password_confirm"
              class="form-control"
              pattern=".{6,10}"
              placeholder="Must be same as above."
              required
            />
            <span class="error-message" v-if="confirmPasswordError">{{
              confirmPasswordError
            }}</span>
          </div>

          <!-- Email -->
          <div class="input-group">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              v-model="json_data.email"
              class="form-control"
              required
            />
          </div>

          <div class="input-group checkbox-inline">
            <input
              type="checkbox"
              id="okMail"
              class="ml-2 mr-2"
              v-model="json_data.okMail"
            />
            <label for="okMail" class="checkbox-label"
              >OK to send occasional email announcements and quarterly TAIR
              newsletter</label
            >
          </div>

          <!-- First Name -->
          <div class="input-group">
            <label for="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              v-model="json_data.firstName"
              class="form-control"
              required
            />
          </div>

          <!-- Last Name -->
          <div class="input-group">
            <label for="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              v-model="json_data.lastName"
              class="form-control"
              required
            />
          </div>

          <!-- Institution Name -->
          <div class="input-group">
            <label for="institutionName">Institution Name</label>
            <input
              type="text"
              id="institutionName"
              v-model="json_data.institutionName"
              class="form-control"
              required
            />
          </div>

          <div class="input-group checkbox-inline">
            <input
              type="checkbox"
              id="okGeneSymbol"
              v-model="json_data.okGeneSymbol"
              class="ml-2 mr-2"
            />
            <label for="okGeneSymbol" class="checkbox-label"
              >Enable Gene Symbol Registration</label
            >
          </div>

          <!-- Job Title -->
          <div class="input-group">
            <label for="jobTitle">Job Title</label>
            <input
              type="text"
              id="jobTitle"
              v-model="json_data.jobTitle"
              class="form-control"
            />
          </div>

          <!-- Phone -->
          <div class="input-group">
            <label for="primaryJobTitle">Phone</label>
            <input
              type="text"
              id="phone"
              v-model="json_data.phone"
              class="form-control"
            />
          </div>

          <!-- Street Address -->
          <div class="input-group">
            <label for="streetAddress">Street Address</label>
            <textarea
              id="streetAddress"
              v-model="json_data.address"
              class="form-control"
            ></textarea>
          </div>

          <!-- Country -->
          <div class="input-group">
            <label for="country">Country</label>
            <input
              type="text"
              id="country"
              v-model="json_data.country"
              class="form-control"
            />
          </div>

          <!-- State/Province -->
          <div class="input-group">
            <label for="stateProvince">State/Province</label>
            <input
              type="text"
              id="stateProvince"
              v-model="json_data.state"
              class="form-control"
            />
          </div>

          <!-- City -->
          <div class="input-group">
            <label for="city">City</label>
            <input
              type="text"
              id="city"
              v-model="json_data.city"
              class="form-control"
            />
          </div>

          <!-- Zipcode -->
          <div class="input-group">
            <label for="zipcode">Zipcode</label>
            <input
              type="text"
              id="zipcode"
              v-model="json_data.postalCode"
              class="form-control"
            />
          </div>
        </div>

        <!-- Success Message -->
        <div v-if="successMessage" class="alert alert-success mt-3">
          {{ successMessage }}
        </div>

        <!-- Display form validation errors -->
        <div v-if="formErrors.length" class="alert alert-danger mt-3">
          <p v-for="error in formErrors" :key="error">{{ error }}</p>
        </div>

        <!-- ORCID Notice -->
        <div class="alert alert-info mt-3">
          You will be asked to link your ORCID account after login. This will enable TAIR access.
        </div>

        <!-- Submit Button -->
        <button
          type="submit"
          class="btn btn-primary mt-2"
          :disabled="isLoading"
        >
          <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-if="!isLoading">Register</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
//http://localhost:8082/register
import BaseEntry from "@/components/detail/BaseEntry";
import { mapGetters } from "vuex";
import Api from "@/services/Api";

export default {
  name: "RegistrationDetail",
  components: {
    BaseEntry,
  },
  data() {
    return {
      json_data: {
        username: "",
        password: "",
        password_confirm: "",
        email: "",
        okMail: false,
        okGeneSymbol: false,
        firstName: "",
        lastName: "",
        institutionName: "",
        jobTitle: "",
        phone: "",
        address: "",
        country: "",
        state: "",
        city: "",
        postalCode: "",
        labName: "",
      },
      formErrors: [],
      formattedAddress: "",
      loading: false,
      loadingError: false,
      successMessage: "",
      isLoading: false,
      passwordError: "",
      confirmPasswordError: "",
      // communityId: "",
    };
  },

  async mounted() {
    document.title = "Person Registration";
  },
  watch: {
    // Watching password changes
    "json_data.password"(newVal) {
      this.passwordError = "";
      if (newVal.length > 0 && (newVal.length < 6 || newVal.length > 10)) {
        this.passwordError = "Password must be between 6 and 10 characters.";
      }
    },
    // Watching password confirmation changes
    "json_data.password_confirm"(newVal) {
      this.confirmPasswordError = "";
      if (newVal !== this.json_data.password) {
        this.confirmPasswordError = "Passwords do not match.";
      }
    },
  },
  methods: {
    ...mapGetters("authProfile", ["getLoginUrl"]),
    async registerPerson() {
      this.formErrors = [];
      if (this.passwordError || this.confirmPasswordError) {
        this.formErrors.push(this.passwordError, this.confirmPasswordError);
        return; // Stop the form submission if there are errors
      }
      this.isLoading = true;
      if (
        this.json_data.password_confirm.length < 6 ||
        this.json_data.password_confirm.length > 10
      ) {
        this.formErrors.push("Password must be between 6 and 10 characters.");
        return;
      }
      try {
        const registerDetails = this.json_data;

        const response = await Api().post(
          "user/register-person",
          registerDetails
        );
        this.isLoading = false;

        if (response.status === 200) {
          this.formErrors = [];
          this.successMessage = "Person registered successfully.";

          setTimeout(() => {
            this.successMessage = "";
            window.open(this.getLoginUrl(), "_self");
          }, 2000); // 1000 milliseconds = 1 second
        }
      } catch (error) {
        this.isLoading = false;
        // Checking if the error response has a data property
        if (error.response && error.response.data) {
          // If the server sent a specific error message, display it
          if (
            typeof error.response.data === "object" &&
            error.response.data.error
          ) {
            this.formErrors.push(error.response.data.error);
          } else if (typeof error.response.data === "string") {
            // Handle plain string responses
            this.formErrors.push(error.response.data);
          }
        } else {
          // Fallback error message
          this.formErrors.push(
            "An unexpected error occurred. Please try again."
          );
        }
      }
    },
    //Getters
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
.registration-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.registration-form {
  border: 1px solid #ccc;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin: auto;
}

.input-container {
  /* overflow-y: scroll;
  height: 550px; */
}

.input-group {
  display: flex;
  flex-wrap: wrap; /* Allows elements to wrap to next line on small screens */
  align-items: center; /* Vertically centers the checkbox with the label text */
  gap: 10px; /* Space between label and checkbox */
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block; /* Ensure labels are full width */
}

.input-group input,
.input-group textarea {
  width: 100%; /* Set input elements to take full width */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.input-group select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-group span {
  padding-left: 5px; /* Add padding to align the 'Gene Class Symbol' value */
}

.input-group.checkbox-inline {
  display: flex;
  align-items: center; /* Vertically center align the label and checkbox */
  vertical-align: center;
  justify-content: flex-start; /* Align items to the start of the container */
  padding-top: 10px;
  padding-bottom: 10px;
}

.input-group.checkbox-inline .checkbox-label {
  margin-right: 10px; /* Or any space you see fit */
  vertical-align: top;
}
.input-group.checkbox-inline input[type="checkbox"] {
  width: auto; /* Remove full width from checkboxes */
  transform: scale(2.5);
  vertical-align: top;
}

.error-message {
  color: #dc3545; /* Red for errors */
  /* Add other styling as needed */
}

@media (max-width: 768px) {
  .registration-container {
    width: 90%;
  }
}
</style>
